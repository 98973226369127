<div class="" *ngIf="!isDesktopDevice && isBrowser" style="width: 100%">
  <mat-sidenav-container style="width: 100%">
    <mat-sidenav #sidenav position="end" style="overflow-x:hidden;">
      <div class="sidenav_container sidenav_container-mobile sidenav_container-table" (sidenavClose)="sidenav.toggle()">
        <div fxLayout="column" style="height: 100vh; overflow-x:hidden;">
          <div style="margin-top: 35px;">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;margin-left:18px; text-align:center; padding-left:8px;">
              <div fxLayout="column" fxLayoutAlign="start start" style="">
                <a (click)="goDown1()" class="phone-text">{{ 'Courses' | translate }}</a>
                <a (click)="goDown2()" style=" margin-top: 14px;" class="phone-text">{{ 'Education_process' | translate }}</a>
                <a (click)="goDown3()" style=" margin-top: 14px;" class="phone-text">{{ 'Comments' | translate }}</a>
                <a (click)="goDown4()" style=" margin-top: 14px;" class="phone-text">{{ 'About_us' | translate }}</a>
              </div>
              <button mat-icon-button (click)="sidenav.toggle()" style="margin-right: 6px;top:0;right:0;position:absolute;margin-top:21px;">
                <img style="width:20px;margin-left:10px;margin-right:17px;" src="/images/menu_close_light.svg" alt="menuClose" />
              </button>
            </div>
          </div>

          <div></div>


          <div class=" mt-auto justify-content-around" style="padding-bottom:26px;margin-left:18px;">
            <div *ngIf="currentCountry==='Россия'">
              <p class="phone-mobile phone-table">{{'phone_numru' | translate}}</p>
            </div>
            <!--<div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
              <p class="phone-mobile phone-table">{{'phone_numkz' | translate}}</p>
            </div>-->
            <!--<img class="img-logo" src="/images/facebook.svg" alt="Facebook" />
  <img class="img-logo" src="/images/instagram.svg" alt="Instagram" />-->
            <img class="img-logo" src="/images/vk.svg" alt="VK" />
            <img class="img-logo" src="/images/telegram.svg" alt="Telegram" />
          </div>
        </div>
      </div>

    </mat-sidenav>

    <mat-sidenav-content style="height: 100vh; overflow-x: hidden">
      <!--(onCountryChange)="onCountryChange($event)"
      (sidenavToggle)="sidenav.toggle()"-->
      <app-courses-toolbar (sidenavToggle)="sidenav.toggle()"></app-courses-toolbar>

      <div class="">
        <picture>
          <source srcset="/images/adiservice-bg-table.svg" type="image/svg+xml" media="(min-width:480px) and (orientation: landscape)" />

          <img class="position-absolute h-100 w-100 marginSpecial"
               src="/images/mobile-bg.svg"
               alt="addiFirstBg"
               style="object-fit: fill; top: 0%; left: 0; object-position: center" />
        </picture>


        <div class="ml-3 mr-3" style="z-index: 50; position: absolute">
          <div class="" style="z-index: 50 !important">
            <div class="aditionalServicesMainText-mobile"
                 style="margin-top: 6.6vh; font-size: 4.1em !important">
              {{ "Additional_services" | translate }}
            </div>
            <p class="aditionalServicesText-mobile mb-0"
               style="margin-top: 4.07vh">
              {{ "In_addition_to_online_training_in" | translate }}
            </p>
            <div class="d-flex" style="align-items: center; margin-top: 4.35vh">
              <img src="/images/addserviceArrowRight.svg"
                   alt="arrowRight"
                   style="width: 5em" />
              <a class="aditionalServicesBold-mobile pl-3"
                 (click)="
                corp.scrollIntoView({ behavior: 'smooth', block: 'start' })
              ">{{ "Corporate_training_and_ertification" | translate }}</a>
            </div>
            <div class="d-flex" style="align-items: center; margin-top: 4.35vh">
              <img src="/images/addserviceArrowRight.svg"
                   alt="arrowRight"
                   style="width: 5em" />
              <a class="aditionalServicesBold-mobile pl-3"
                 (click)="
                methodology.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                })
              ">{{ "Implementation_of_the_PRINCE2_methodology" | translate }}</a>
            </div>
            <div class="d-flex" style="align-items: center; margin-top: 3.7vh">
              <img src="/images/addserviceArrowRight.svg"
                   alt="arrowRight"
                   style="width: 5em" />
              <a class="aditionalServicesBold-mobile pl-3"
                 (click)="
                informationsystem.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                })
              ">{{ "Implementation_of_an_Information" | translate }}</a>
            </div>
          </div>
        </div>

        <div class="marginSpecial2" style="top: 100vh; position: absolute; width: 100%">
          <div class="" style="z-index: 50 !important; margin-top: 0">
            <div #corp
                 class="aditionalServicesMainText-mobile mr-3 ml-3"
                 style="text-align: center;">
              {{ "Corporate_training_and_certification" | translate }}
            </div>
            <div style="" class="">
              <div class="mr-3 ml-3">
                <p class="aditionalServicesText-mobile mb-0 mr-0"
                   style="margin-top: 2vh">
                  {{ "Training_under_the_PRINCE2_Foundation_and" | translate }}
                </p>
                <ol class="pl-3 mb-0" style="margin-top: 2vh">
                  <li class="aditionalServicesText-mobile py-1" style="">
                    {{ "Online_training" | translate }}
                  </li>
                  <li class="aditionalServicesText-mobile py-1" style="">
                    {{ "Remote_training" | translate }}
                  </li>
                  <li class="aditionalServicesText-mobile py-1" style="">
                    {{ "Offline_training" | translate }}
                  </li>
                </ol>

                <div style="">
                  <p class="aditionalServicesText-mobile mr-0"
                     style="margin-top: 3.88vh">
                    <strong>{{ "Online_training" | translate }}</strong>
                    {{ "Online_training_is" | translate }}
                  </p>
                  <p class="aditionalServicesText-mobile mr-0">
                    <strong>{{ "With_remote_training" | translate }}</strong>
                    {{ "the_trainer_conducts" | translate }}<br />
                    {{ "Listeners_are_connected" | translate }}<br />
                    {{ "The_advantage_of_this" | translate }} <br />{{
                    "between_the_listener" | translate
                    }}<br />
                    {{ "A_similar_method" | translate }}<br />
                    {{ "cities_or_offices" | translate }}
                  </p>
                  <p class="aditionalServicesText-mobile mr-0">
                    <strong>{{ "Offline_training" | translate }}</strong>
                    {{ "is_a_classic_way" | translate }} <br />
                    {{ "presence_of_a_trainer" | translate }} <br />
                    {{ "of_the_Customer" | translate }}
                  </p>
                  <p class="aditionalServicesText-mobile mr-0">
                    {{
                    "The_PRINCE2_Foundation_and_PRINCE2_Practitioner_1"
                      | translate
                    }}
                    <br />
                    {{ "online_or_in_paper" | translate }}
                  </p>
                </div>

                <div style="margin-top: 3.18vh">
                  <p class="textGray-mobile">
                    <a (click)="
                      getaply.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                      })
                    "
                       class="zayavkaText">{{ "Fill_out_the_application" | translate }}</a>
                    {{ "and_our_manager" | translate }}<br />
                    {{ "the_training" | translate }}
                  </p>
                </div>
              </div>
              <div #methodology
                   class="aditionalServicesMainText-mobile mr-3 ml-3"
                   style="text-align: center; margin-top: 2vh">
                {{ "Implementation_of_the" | translate }}
              </div>
              <div style="">
                <div class="mr-3 ml-3">
                  <p class="aditionalServicesText-mobile mb-0 mr-0"
                     style="margin-top: 2vh">
                    {{ "The_service_includes_the_development" | translate }}
                  </p>

                  <p class="aditionalServicesText-mobile mr-0"
                     style="color: #2e5b5f; font-weight: bold; margin-top: 2vh">
                    {{ "As_a_result_of_the_provision" | translate }}
                  </p>

                  <ul class="pl-3 mb-0" style="margin-top: 2vh">
                    <li class="aditionalServicesText-mobile py-1" style="">
                      {{ "Regulations_and_procedures" | translate }}
                    </li>
                    <li class="aditionalServicesText-mobile py-1" style="">
                      {{ "Organizational_structure" | translate }}
                    </li>
                    <li class="aditionalServicesText-mobile py-1" style="">
                      {{ "Roles_descriptions" | translate }}
                    </li>
                    <li class="aditionalServicesText-mobile py-1" style="">
                      {{ "Job_descriptions" | translate }}
                    </li>
                    <li class="aditionalServicesText-mobile py-1" style="">
                      {{ "Document_templates" | translate }}
                    </li>
                    <li class="aditionalServicesText-mobile py-1" style="">
                      {{ "Report_templates" | translate }}
                    </li>
                  </ul>

                  <div style="margin-top: 3.18vh">
                    <p class="textGray-mobile">
                      <a (click)="
                        getaply.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start'
                        })
                      "
                         class="zayavkaText">{{ "Fill_out_the_application" | translate }}</a>
                      {{ "to_receive_a" | translate }}<br />
                      {{ "of_the_PRINCE2" | translate }}
                    </p>
                  </div>
                </div>
                <div class="">
                  <div #informationsystem
                       class="aditionalServicesMainText-mobile mr-3 ml-3"
                       style="text-align: center; margin-top: 3vh">
                    {{ "Implementation_of" | translate }}
                  </div>
                  <div class="mr-3 ml-3">
                    <p class="aditionalServicesText-mobile mr-0 mb-0"
                       style="margin-top: 2.14vh">
                      {{ "The_information_system" | translate }}<br />
                      {{ "is_designed" | translate }}
                    </p>
                    <p class="aditionalServicesText-mobile mr-0 mb-0"
                       style="margin-top: 2.14vh">
                      {{ "The_Information" | translate }}<br />
                      {{ "automation_of" | translate }}<br />
                      {{ "used_both" | translate }}<br />
                      {{ "their_projects" | translate }}<br />{{
                      "methodology" | translate
                      }}
                    </p>
                    <p class="aditionalServicesText-mobile mr-0 mb-0"
                       style="margin-top: 2.14vh">
                      {{ "The_system_is" | translate }}<br />{{
                      "to_prioritize_and" | translate
                      }}<br />
                      {{ "is_a_centralized" | translate }}<br />
                      {{ "projects_that_exist" | translate }}<br />{{
                      "the_System" | translate
                      }}
                    </p>

                    <div>
                      <p class="aditionalServicesText-mobile mr-0"
                         style="color: #2e5b5f; font-weight: bold; margin-top: 2vh">
                        {{ "Functional_areas" | translate }}
                        {{ "Portfolio_management" | translate }}
                        {{ "project_environment" | translate }}
                      </p>

                      <ul class="pl-3 mb-0" style="margin-top: 2vh">
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Collecting_project_ideas" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Project_Management" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Program_Management" | translate }};
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Project_Portfolio_Management" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{
                          "Development_of_project_management_expertise"
                            | translate
                          }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Resource_planning" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Realization_of_benefits" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{
                          "Reporting_across_all_functional_areas" | translate
                          }}
                        </li>
                      </ul>
                    </div>

                    <div class="" style="">
                      <p class="aditionalServicesText-mobile mr-0"
                         style="color: #2e5b5f; font-weight: bold; margin-top: 2vh">
                        {{ "The_benefits_of" | translate }}
                      </p>

                      <ul class="pl-3 mb-0" style="margin-top: 2vh">
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Increasing_the" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Concentration_on_priority" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Build_management_expertise" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Using_the_resources" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Implementation_of_a" | translate }}
                        </li>
                      </ul>

                      <div style="margin-top: 3.14vh">
                        <p class="textGray-mobile">
                          <a (click)="
                            getaply.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start'
                            })
                          "
                             class="zayavkaText">{{ "Fill_out_the_application" | translate }}</a>
                          {{ "to_receive_a_proposal" | translate }}<br />
                          {{ "System_for_project" | translate }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="" fxLayout="column" fxFlexFill>
                    <div fxFlex=""
                         fxLayout="column"
                         fxLayoutAlign="center center"
                         class="w-100">
                      <div class="text-center" style="">
                        <div #getaply
                             class="aditionalServicesMainText-mobile mb-3"
                             style="text-align: center">
                          {{ "Application" | translate }}
                        </div>
                        <form name="requestForm"
                              [formGroup]="requestForm"
                              fxLayout="column"
                              class="text-left"
                              style="align-items: center">
                          <div fxFlex="100"
                               class="input-mobile"
                               fxLayout="column"
                               style="">
                            <div class="reg-label input-mobile-text">
                              {{ "Company_name" | translate }}
                            </div>
                            <mat-form-field class="w-100"
                                            floatLabel="never"
                                            appearance="outline">
                              <input class="clientInfo"
                                     formControlName="companyName"
                                     [(ngModel)]="model.companyName"
                                     floatLabel="never"
                                     matInput />
                            </mat-form-field>
                            <div class="reg-label input-mobile-text">
                              {{ "Name" | translate }}
                              <span class="red-star">*</span>
                            </div>
                            <mat-form-field class="w-100"
                                            floatLabel="never"
                                            appearance="outline">
                              <input class="clientInfo"
                                     formControlName="name"
                                     [(ngModel)]="model.firstName"
                                     floatLabel="never"
                                     required
                                     matInput />
                              <mat-error class="no-margin"
                                         *ngIf="checkFormError('name', 'required')">
                                {{ "must_input" | translate }}
                              </mat-error>
                            </mat-form-field>
                            <div class="reg-label input-mobile-text">
                              {{ "Surname" | translate }}
                            </div>
                            <mat-form-field class="w-100"
                                            floatLabel="never"
                                            appearance="outline">
                              <input class="clientInfo"
                                     formControlName="surname"
                                     [(ngModel)]="model.lastName"
                                     floatLabel="never"
                                     matInput />
                            </mat-form-field>
                          </div>
                          <div fxFlex="100"
                               class="input-mobile"
                               fxLayout="column"
                               style="">
                            <div class="reg-label input-mobile-text">
                              {{ "Phone_number" | translate }}
                              <span class="red-star">*</span>
                            </div>
                            <mat-form-field class="w-100"
                                            floatLabel="never"
                                            appearance="outline">
                              <input type="tel"
                                     prefix="{{ prefix }}"
                                     mask=" 000 000 00 00"
                                     [showMaskTyped]="true"
                                     placeHolderCharacter="_"
                                     name="phone_number"
                                     #pnumber
                                     aria-describedby="name"
                                     formControlName="phoneNumber"
                                     floatLabel="never"
                                     matInput
                                     class="phone-input w-100"
                                     [(ngModel)]="phone_number"
                                     placeholder="+7 (___) ___ __ __" />
                              <mat-error class="no-margin"
                                         *ngIf="checkFormError('phoneNumber', 'required')">
                                {{ "must_input" | translate }}
                              </mat-error>
                            </mat-form-field>

                            <div class="reg-label input-mobile-text">
                              {{ "E-mail" | translate }}
                            </div>
                            <mat-form-field class="w-100"
                                            floatLabel="never"
                                            appearance="outline">
                              <input class="clientInfo"
                                     formControlName="email"
                                     [(ngModel)]="model.emailAddress"
                                     floatLabel="never"
                                     matInput />
                            </mat-form-field>
                            <div class="reg-label input-mobile-text">
                              {{ "Application_subject" | translate }}
                              <span class="red-star">*</span>
                            </div>
                            <mat-form-field class="w-100" appearance="outline">
                              <mat-select formControlName="subject"
                                          [(ngModel)]="selectedValue"
                                          required
                                          matInput
                                          floatLabel="never">
                                <mat-option class="input-mobile-aply-text"
                                            *ngFor="let item of subjects"
                                            [value]="item">
                                  {{ item | translate }}
                                </mat-option>
                              </mat-select>
                              <mat-error class="no-margin"
                                         *ngIf="checkFormError('subject', 'required')">
                                {{ "must_input" | translate }}
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div fxFlex="100"
                               class="input-mobile"
                               fxLayout="column"
                               style="">
                            <div class="reg-label input-mobile-text">
                              {{ "Comment_1" | translate }}
                            </div>
                            <mat-form-field class="w-100"
                                            fxFlex
                                            appearance="outline">
                              <textarea matInput
                                        [(ngModel)]="model.text"
                                        formControlName="comment"
                                        cdkTextareaAutosize
                                        cdkAutosizeMinRows="4"
                                        cdkAutosizeMaxRows="4"></textarea>
                            </mat-form-field>
                          </div>
                        </form>
                        <button class="px-0 buttonstyle-mobile"
                                (click)="sendApplication()"
                                mat-button
                                color="accent"
                                style="width: 11.19vw; font-size: 1.125em">
                          {{ "send" | translate }}
                        </button>
                      </div>
                    </div>

                    <div class="">
                      <div class="ab-footer-mobile ab-footer-table" fxLayout="column" fxFlex="25.55">
                        <div fxFlex="21" style="">
                          <div fxLayout="column" fxFlexFill style="">
                            <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 30em;" />
                            <!--<img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />-->
                            <!--<div fxFlex fxLayoutAlign="start" style="padding-top: 1.85vh;">-->
                            <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
        <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
                            <!--<img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
        <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />-->
                            <!--</div>-->
                          </div>
                        </div>
                        <div fxLayout="column" class="" fxFlex="22" style="margin-left: 3.73vw; margin-bottom:2vh;">
                          <div fxLayout="column" class="pb-4" fxFlexFill>

                            <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown1()" style="">{{'Courses' | translate}}</a>
                            <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown2()" style="">{{'Education_process' | translate}}</a>
                            <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown3()" style="">{{'Comments' | translate}}</a>
                            <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown4()" style="">{{'About_us' | translate}}</a>
                          </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div fxLayout="column" fxFlex="30" class="py-2" style="margin-left: 3.73vw;">
                          <div fxLayout="column" fxFlexFill>
                            <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" routerLink="/additional-services" style="">{{'Additional_services' | translate}}</a>
                            <a *ngIf="currentCountry==='Россия'" fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="">{{'support' | translate}}</a>
                            <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                              <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/payment-rules">{{'Pravila_Payment' | translate}}</a>
                            </div>
                            <div *ngIf="currentCountry==='Россия'">
                              <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table ofertaCss" routerLink="/oferta" style="">{{'public_offer_1' | translate}}</a>
                            </div>
                            <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                              <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table ofertaCss" routerLink="/ofertakz" style="">{{'public_offer_1' | translate}}</a>
                            </div>
                            <div *ngIf="currentCountry==='Россия'">
                              <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table mb-0 ofertaCss" style="color: white !important;" routerLink="/privacy">{{'Personal_data_processing_policy' | translate}}</a>

                            </div>
                            <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                              <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table mb-0 ofertaCss" style="color: white !important;" routerLink="/privacykz">{{'Personal_data_processing_policy' | translate}}</a>

                            </div>
                          </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div fxLayout="column" class="py-2" fxFlex="30" style="margin-left: 3.73vw;">
                          <div fxLayout="column" fxFlexFill>
                            <div *ngIf="currentCountry==='Россия'">
                              <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addressru' | translate}}</p>

                            </div>
                            <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                              <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addresskz' | translate}}</p>

                            </div>
                            <div *ngIf="currentCountry==='Россия'">
                              <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numru' | translate}}</p>

                            </div>
                            <!--<div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                              <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numkz' | translate}}</p>

                            </div>-->
                            <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" style="" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>
                          </div>
                        </div>
                        <div fxLayout="column" fxFlex="22" style="margin-left: 3.73vw;">
                          <div fxLayout="row" fxFlexFill>
                            <img src="/images/axelos.svg" class="axelos-img-mobile axelos-img-table" alt="axelosLogo" style="" />
                            <div fxFlex fxLayoutAlign="start center" style="align-items:center;margin-left: 10px;">
                              <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img-mobile footer-contact-img-table " />
          <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img-mobile footer-contact-img-table" />-->
                              <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img-mobile footer-contact-img-table" />
                              <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img-mobile footer-contact-img-table" />
                              <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard-mobile" />
                              <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa-mobile" />
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div class="" *ngIf="isDesktopDevice && !isBrowser" style="width: 100%">
  <mat-sidenav-container style="width: 100%">
    <mat-sidenav #sidenav position="end" style="overflow-x:hidden;">
      <div class="sidenav_container sidenav_container-mobile sidenav_container-table" (sidenavClose)="sidenav.toggle()">
        <div fxLayout="column" style="height: 100vh; overflow-x:hidden;">
          <div style="margin-top: 35px;">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;margin-left:18px; text-align:center; padding-left:8px;">
              <div fxLayout="column" fxLayoutAlign="start start" style="">
                <a (click)="goDown1()" class="phone-text">{{ 'Courses' | translate }}</a>
                <a (click)="goDown2()" style=" margin-top: 14px;" class="phone-text">{{ 'Education_process' | translate }}</a>
                <a (click)="goDown3()" style=" margin-top: 14px;" class="phone-text">{{ 'Comments' | translate }}</a>
                <a (click)="goDown4()" style=" margin-top: 14px;" class="phone-text">{{ 'About_us' | translate }}</a>
              </div>
              <button mat-icon-button (click)="sidenav.toggle()" style="margin-right: 6px;top:0;right:0;position:absolute;margin-top:21px;">
                <img style="width:20px;margin-left:10px;margin-right:17px;" src="/images/menu_close_light.svg" alt="menuClose" />
              </button>
            </div>
          </div>

          <div></div>

          <div class=" mt-auto justify-content-around" style="padding-bottom:26px;margin-left:18px;">
            <div *ngIf="currentCountry==='Россия'">
              <p class="phone-mobile phone-table">{{'phone_numru' | translate}}</p>
            </div>
            <!--<div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
              <p class="phone-mobile phone-table">{{'phone_numkz' | translate}}</p>
            </div>-->
            <!--<img class="img-logo" src="/images/facebook.svg" alt="Facebook" />
  <img class="img-logo" src="/images/instagram.svg" alt="Instagram" />-->
            <img class="img-logo" src="/images/vk.svg" alt="VK" />
            <img class="img-logo" src="/images/telegram.svg" alt="Telegram" />
          </div>
        </div>
      </div>

    </mat-sidenav>

    <mat-sidenav-content style="height: 100vh; overflow-x: hidden">
      <app-courses-toolbar (sidenavToggle)="sidenav.toggle()"></app-courses-toolbar>

      <div class="">
        <picture>
          <source srcset="/images/adiservice-bg-table.svg" type="image/svg+xml" media="(min-width:480px) and (orientation: landscape)" />

          <img class="position-absolute h-100 w-100 marginSpecial"
               src="/images/mobile-bg.svg"
               alt="addiFirstBg"
               style="object-fit: fill; top: 0%; left: 0; object-position: center" />
        </picture>


        <div class="ml-3 mr-3" style="z-index: 50; position: absolute">
          <div class="" style="z-index: 50 !important">
            <div class="aditionalServicesMainText-mobile"
                 style="margin-top: 6.6vh; font-size: 4.1em !important">
              {{ "Additional_services" | translate }}
            </div>
            <p class="aditionalServicesText-mobile mb-0"
               style="margin-top: 4.07vh">
              {{ "In_addition_to_online_training_in" | translate }}
            </p>
            <div class="d-flex" style="align-items: center; margin-top: 4.35vh">
              <img src="/images/addserviceArrowRight.svg"
                   alt="arrowRight"
                   style="width: 5em" />
              <a class="aditionalServicesBold-mobile pl-3"
                 (click)="
                corp.scrollIntoView({ behavior: 'smooth', block: 'start' })
              ">{{ "Corporate_training_and_ertification" | translate }}</a>
            </div>
            <div class="d-flex" style="align-items: center; margin-top: 4.35vh">
              <img src="/images/addserviceArrowRight.svg"
                   alt="arrowRight"
                   style="width: 5em" />
              <a class="aditionalServicesBold-mobile pl-3"
                 (click)="
                methodology.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                })
              ">{{ "Implementation_of_the_PRINCE2_methodology" | translate }}</a>
            </div>
            <div class="d-flex" style="align-items: center; margin-top: 3.7vh">
              <img src="/images/addserviceArrowRight.svg"
                   alt="arrowRight"
                   style="width: 5em" />
              <a class="aditionalServicesBold-mobile pl-3"
                 (click)="
                informationsystem.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                })
              ">{{ "Implementation_of_an_Information" | translate }}</a>
            </div>
          </div>
        </div>

        <div class="marginSpecial2" style="top: 100vh; position: absolute; width: 100%">
          <div class="" style="z-index: 50 !important; margin-top: 0">
            <div #corp
                 class="aditionalServicesMainText-mobile mr-3 ml-3"
                 style="text-align: center;">
              {{ "Corporate_training_and_certification" | translate }}
            </div>
            <div style="" class="">
              <div class="mr-3 ml-3">
                <p class="aditionalServicesText-mobile mb-0 mr-0"
                   style="margin-top: 2vh">
                  {{ "Training_under_the_PRINCE2_Foundation_and" | translate }}
                </p>
                <ol class="pl-3 mb-0" style="margin-top: 2vh">
                  <li class="aditionalServicesText-mobile py-1" style="">
                    {{ "Online_training" | translate }}
                  </li>
                  <li class="aditionalServicesText-mobile py-1" style="">
                    {{ "Remote_training" | translate }}
                  </li>
                  <li class="aditionalServicesText-mobile py-1" style="">
                    {{ "Offline_training" | translate }}
                  </li>
                </ol>

                <div style="">
                  <p class="aditionalServicesText-mobile mr-0"
                     style="margin-top: 3.88vh">
                    <strong>{{ "Online_training" | translate }}</strong>
                    {{ "Online_training_is" | translate }}
                  </p>
                  <p class="aditionalServicesText-mobile mr-0">
                    <strong>{{ "With_remote_training" | translate }}</strong>
                    {{ "the_trainer_conducts" | translate }}<br />
                    {{ "Listeners_are_connected" | translate }}<br />
                    {{ "The_advantage_of_this" | translate }} <br />{{
                    "between_the_listener" | translate
                    }}<br />
                    {{ "A_similar_method" | translate }}<br />
                    {{ "cities_or_offices" | translate }}
                  </p>
                  <p class="aditionalServicesText-mobile mr-0">
                    <strong>{{ "Offline_training" | translate }}</strong>
                    {{ "is_a_classic_way" | translate }} <br />
                    {{ "presence_of_a_trainer" | translate }} <br />
                    {{ "of_the_Customer" | translate }}
                  </p>
                  <p class="aditionalServicesText-mobile mr-0">
                    {{
                    "The_PRINCE2_Foundation_and_PRINCE2_Practitioner_1"
                      | translate
                    }}
                    <br />
                    {{ "online_or_in_paper" | translate }}
                  </p>
                </div>

                <div style="margin-top: 3.18vh">
                  <p class="textGray-mobile">
                    <a (click)="
                      getaply.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                      })
                    "
                       class="zayavkaText">{{ "Fill_out_the_application" | translate }}</a>
                    {{ "and_our_manager" | translate }}<br />
                    {{ "the_training" | translate }}
                  </p>
                </div>
              </div>
              <div #methodology
                   class="aditionalServicesMainText-mobile mr-3 ml-3"
                   style="text-align: center; margin-top: 2vh">
                {{ "Implementation_of_the" | translate }}
              </div>
              <div style="">
                <div class="mr-3 ml-3">
                  <p class="aditionalServicesText-mobile mb-0 mr-0"
                     style="margin-top: 2vh">
                    {{ "The_service_includes_the_development" | translate }}
                  </p>

                  <p class="aditionalServicesText-mobile mr-0"
                     style="color: #2e5b5f; font-weight: bold; margin-top: 2vh">
                    {{ "As_a_result_of_the_provision" | translate }}
                  </p>

                  <ul class="pl-3 mb-0" style="margin-top: 2vh">
                    <li class="aditionalServicesText-mobile py-1" style="">
                      {{ "Regulations_and_procedures" | translate }}
                    </li>
                    <li class="aditionalServicesText-mobile py-1" style="">
                      {{ "Organizational_structure" | translate }}
                    </li>
                    <li class="aditionalServicesText-mobile py-1" style="">
                      {{ "Roles_descriptions" | translate }}
                    </li>
                    <li class="aditionalServicesText-mobile py-1" style="">
                      {{ "Job_descriptions" | translate }}
                    </li>
                    <li class="aditionalServicesText-mobile py-1" style="">
                      {{ "Document_templates" | translate }}
                    </li>
                    <li class="aditionalServicesText-mobile py-1" style="">
                      {{ "Report_templates" | translate }}
                    </li>
                  </ul>

                  <div style="margin-top: 3.18vh">
                    <p class="textGray-mobile">
                      <a (click)="
                        getaply.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start'
                        })
                      "
                         class="zayavkaText">{{ "Fill_out_the_application" | translate }}</a>
                      {{ "to_receive_a" | translate }}<br />
                      {{ "of_the_PRINCE2" | translate }}
                    </p>
                  </div>
                </div>
                <div class="">
                  <div #informationsystem
                       class="aditionalServicesMainText-mobile mr-3 ml-3"
                       style="text-align: center; margin-top: 3vh">
                    {{ "Implementation_of" | translate }}
                  </div>
                  <div class="mr-3 ml-3">
                    <p class="aditionalServicesText-mobile mr-0 mb-0"
                       style="margin-top: 2.14vh">
                      {{ "The_information_system" | translate }}<br />
                      {{ "is_designed" | translate }}
                    </p>
                    <p class="aditionalServicesText-mobile mr-0 mb-0"
                       style="margin-top: 2.14vh">
                      {{ "The_Information" | translate }}<br />
                      {{ "automation_of" | translate }}<br />
                      {{ "used_both" | translate }}<br />
                      {{ "their_projects" | translate }}<br />{{
                      "methodology" | translate
                      }}
                    </p>
                    <p class="aditionalServicesText-mobile mr-0 mb-0"
                       style="margin-top: 2.14vh">
                      {{ "The_system_is" | translate }}<br />{{
                      "to_prioritize_and" | translate
                      }}<br />
                      {{ "is_a_centralized" | translate }}<br />
                      {{ "projects_that_exist" | translate }}<br />{{
                      "the_System" | translate
                      }}
                    </p>

                    <div>
                      <p class="aditionalServicesText-mobile mr-0"
                         style="color: #2e5b5f; font-weight: bold; margin-top: 2vh">
                        {{ "Functional_areas" | translate }}
                        {{ "Portfolio_management" | translate }}
                        {{ "project_environment" | translate }}
                      </p>

                      <ul class="pl-3 mb-0" style="margin-top: 2vh">
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Collecting_project_ideas" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Project_Management" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Program_Management" | translate }};
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Project_Portfolio_Management" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{
                          "Development_of_project_management_expertise"
                            | translate
                          }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Resource_planning" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Realization_of_benefits" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{
                          "Reporting_across_all_functional_areas" | translate
                          }}
                        </li>
                      </ul>
                    </div>

                    <div class="" style="">
                      <p class="aditionalServicesText-mobile mr-0"
                         style="color: #2e5b5f; font-weight: bold; margin-top: 2vh">
                        {{ "The_benefits_of" | translate }}
                      </p>

                      <ul class="pl-3 mb-0" style="margin-top: 2vh">
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Increasing_the" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Concentration_on_priority" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Build_management_expertise" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Using_the_resources" | translate }}
                        </li>
                        <li class="aditionalServicesText-mobile mr-0 py-1"
                            style="">
                          {{ "Implementation_of_a" | translate }}
                        </li>
                      </ul>

                      <div style="margin-top: 3.14vh">
                        <p class="textGray-mobile">
                          <a (click)="
                            getaply.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start'
                            })
                          "
                             class="zayavkaText">{{ "Fill_out_the_application" | translate }}</a>
                          {{ "to_receive_a_proposal" | translate }}<br />
                          {{ "System_for_project" | translate }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="" fxLayout="column" fxFlexFill>
                    <div fxFlex=""
                         fxLayout="column"
                         fxLayoutAlign="center center"
                         class="w-100">
                      <div class="text-center" style="">
                        <div #getaply
                             class="aditionalServicesMainText-mobile mb-3"
                             style="text-align: center">
                          {{ "Application" | translate }}
                        </div>
                        <form name="requestForm"
                              [formGroup]="requestForm"
                              fxLayout="column"
                              class="text-left"
                              style="align-items: center">
                          <div fxFlex="100"
                               class="input-mobile"
                               fxLayout="column"
                               style="">
                            <div class="reg-label input-mobile-text">
                              {{ "Company_name" | translate }}
                            </div>
                            <mat-form-field class="w-100"
                                            floatLabel="never"
                                            appearance="outline">
                              <input class="clientInfo"
                                     formControlName="companyName"
                                     [(ngModel)]="model.companyName"
                                     floatLabel="never"
                                     matInput />
                            </mat-form-field>
                            <div class="reg-label input-mobile-text">
                              {{ "Name" | translate }}
                              <span class="red-star">*</span>
                            </div>
                            <mat-form-field class="w-100"
                                            floatLabel="never"
                                            appearance="outline">
                              <input class="clientInfo"
                                     formControlName="name"
                                     [(ngModel)]="model.firstName"
                                     floatLabel="never"
                                     required
                                     matInput />
                              <mat-error class="no-margin"
                                         *ngIf="checkFormError('name', 'required')">
                                {{ "must_input" | translate }}
                              </mat-error>
                            </mat-form-field>
                            <div class="reg-label input-mobile-text">
                              {{ "Surname" | translate }}
                            </div>
                            <mat-form-field class="w-100"
                                            floatLabel="never"
                                            appearance="outline">
                              <input class="clientInfo"
                                     formControlName="surname"
                                     [(ngModel)]="model.lastName"
                                     floatLabel="never"
                                     matInput />
                            </mat-form-field>
                          </div>
                          <div fxFlex="100"
                               class="input-mobile"
                               fxLayout="column"
                               style="">
                            <div class="reg-label input-mobile-text">
                              {{ "Phone_number" | translate }}
                              <span class="red-star">*</span>
                            </div>
                            <mat-form-field class="w-100"
                                            floatLabel="never"
                                            appearance="outline">
                              <input type="tel"
                                     prefix="{{ prefix }}"
                                     mask=" 000 000 00 00"
                                     [showMaskTyped]="true"
                                     placeHolderCharacter="_"
                                     name="phone_number"
                                     #pnumber
                                     aria-describedby="name"
                                     formControlName="phoneNumber"
                                     floatLabel="never"
                                     matInput
                                     class="phone-input w-100"
                                     [(ngModel)]="phone_number"
                                     placeholder="+7 (___) ___ __ __" />
                              <mat-error class="no-margin"
                                         *ngIf="checkFormError('phoneNumber', 'required')">
                                {{ "must_input" | translate }}
                              </mat-error>
                            </mat-form-field>

                            <div class="reg-label input-mobile-text">
                              {{ "E-mail" | translate }}
                            </div>
                            <mat-form-field class="w-100"
                                            floatLabel="never"
                                            appearance="outline">
                              <input class="clientInfo"
                                     formControlName="email"
                                     [(ngModel)]="model.emailAddress"
                                     floatLabel="never"
                                     matInput />
                            </mat-form-field>
                            <div class="reg-label input-mobile-text">
                              {{ "Application_subject" | translate }}
                              <span class="red-star">*</span>
                            </div>
                            <mat-form-field class="w-100" appearance="outline">
                              <mat-select formControlName="subject"
                                          [(ngModel)]="selectedValue"
                                          required
                                          matInput
                                          floatLabel="never">
                                <mat-option class="input-mobile-aply-text"
                                            *ngFor="let item of subjects"
                                            [value]="item">
                                  {{ item | translate }}
                                </mat-option>
                              </mat-select>
                              <mat-error class="no-margin"
                                         *ngIf="checkFormError('subject', 'required')">
                                {{ "must_input" | translate }}
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div fxFlex="100"
                               class="input-mobile"
                               fxLayout="column"
                               style="">
                            <div class="reg-label input-mobile-text">
                              {{ "Comment_1" | translate }}
                            </div>
                            <mat-form-field class="w-100"
                                            fxFlex
                                            appearance="outline">
                              <textarea matInput
                                        [(ngModel)]="model.text"
                                        formControlName="comment"
                                        cdkTextareaAutosize
                                        cdkAutosizeMinRows="4"
                                        cdkAutosizeMaxRows="4"></textarea>
                            </mat-form-field>
                          </div>
                        </form>
                        <button class="px-0 buttonstyle-mobile"
                                (click)="sendApplication()"
                                mat-button
                                color="accent"
                                style="width: 11.19vw; font-size: 1.125em">
                          {{ "send" | translate }}
                        </button>
                      </div>
                    </div>

                    <div class="">
                      <div class="ab-footer-mobile ab-footer-table" fxLayout="column" fxFlex="25.55">
                        <div fxFlex="21" style="">
                          <div fxLayout="column" fxFlexFill style="">
                            <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 30em;" />
                            <!--<img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />-->
                            <!--<div fxFlex fxLayoutAlign="start" style="padding-top: 1.85vh;">-->
                            <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
        <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
                            <!--<img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
        <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />-->
                            <!--</div>-->
                          </div>
                        </div>
                        <div fxLayout="column" class="" fxFlex="22" style="margin-left: 3.73vw; margin-bottom:2vh;">
                          <div fxLayout="column" class="pb-4" fxFlexFill>

                            <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown1()" style="">{{'Courses' | translate}}</a>
                            <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown2()" style="">{{'Education_process' | translate}}</a>
                            <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown3()" style="">{{'Comments' | translate}}</a>
                            <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown4()" style="">{{'About_us' | translate}}</a>
                          </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div fxLayout="column" fxFlex="30">
                          <div fxLayout="column" fxFlexFill>
                            <a fxFlex="20" class="mat-title footer-text" routerLink="/additional-services" style="margin-bottom: 0.55vh;">{{'Additional_services' | translate}}</a>
                            <a *ngIf="currentCountry==='Россия'" fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="margin-bottom: 1.20vh;">{{'support' | translate}}</a>
                            <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                              <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/payment-rules">{{'Pravila_Payment' | translate}}</a>
                            </div>
                            <div *ngIf="currentCountry==='Россия'">
                              <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/oferta">{{'public_offer_1' | translate}}</a>
                            </div>
                            <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                              <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh; color: white !important;" routerLink="/ofertakz">{{'public_offer_1' | translate}}</a>
                            </div>
                            <div *ngIf="currentCountry==='Россия'">
                              <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacy">{{'Personal_data_processing_policy' | translate}}</a>
                            </div>
                            <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                              <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacykz">{{'Personal_data_processing_policy' | translate}}</a>
                            </div>

                          </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div fxLayout="column" class="py-2" fxFlex="30" style="margin-left: 3.73vw;">
                          <div fxLayout="column" fxFlexFill>
                            <div *ngIf="currentCountry==='Россия'">
                              <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addressru' | translate}}</p>

                            </div>
                            <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                              <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addresskz' | translate}}</p>

                            </div>
                            <div *ngIf="currentCountry==='Россия'">
                              <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numru' | translate}}</p>

                            </div>
                            <!--<div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                              <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numkz' | translate}}</p>

                            </div>-->
                            <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" style="" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>
                          </div>
                        </div>
                        <div fxLayout="column" fxFlex="22" style="margin-left: 3.73vw;">
                          <div fxLayout="row" fxFlexFill>
                            <img src="/images/axelos.svg" class="axelos-img-mobile axelos-img-table" alt="axelosLogo" style="" />
                            <div fxFlex fxLayoutAlign="start center" style="align-items:center;margin-left: 10px;">
                              <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img-mobile footer-contact-img-table " />
          <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img-mobile footer-contact-img-table" />-->
                              <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img-mobile footer-contact-img-table" />
                              <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img-mobile footer-contact-img-table" />
                              <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard-mobile" />
                              <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa-mobile" />
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div *ngIf="isBrowser && isDesktopDevice">
  <app-courses-toolbar (sidenavToggle)="sidenav.toggle()"></app-courses-toolbar>

  <div fullpage
       id="servicesPage"
       [options]="config"
       (ref)="getRef($event)"
       #servicesPage>
    <div class="section">
      <img class="position-absolute h-100 w-100"
           src="/images/addiFirstBg.svg"
           alt="addiFirstBg"
           style="object-fit: fill" />
      <div class="home-container" style="">
        <div class="position-fixed"
             style="width: 46.63vw; margin-top: 18vh; margin-left: 10vw">
          <div class="aditionalServicesMainText" style="margin-top: 6.6vh">
            {{ "Additional_services" | translate }}
          </div>
          <p class="aditionalServicesText mb-0"
             style="margin-top: 4.07vh; width: 32vw">
            {{ "In_addition_to_online_training_in" | translate }}
          </p>
          <div class="d-flex" style="align-items: center; margin-top: 4.35vh">
            <img src="/images/addserviceArrowRight.svg" alt="arrowRight" />
            <a class="aditionalServicesBold pl-3" href="#corp">
              {{
              "Corporate_training_and_ertification" | translate
              }}
            </a>
          </div>
          <div class="d-flex" style="align-items: center; margin-top: 4.35vh">
            <img src="/images/addserviceArrowRight.svg" alt="arrowRight" />
            <a class="aditionalServicesBold pl-3" href="#methodology">
              {{
              "Implementation_of_the_PRINCE2_methodology" | translate
              }}
            </a>
          </div>
          <div class="d-flex" style="align-items: center; margin-top: 3.7vh">
            <img src="/images/addserviceArrowRight.svg" alt="arrowRight" />
            <a class="aditionalServicesBold pl-3" href="#informationsystem">
              {{
              "Implementation_of_an_Information" | translate
              }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <img class="position-absolute h-100 w-100"
           src="/images/addiSecondBg.svg"
           alt="secondBg"
           style="object-fit: fill" />
      <div class="home-container" style="">
        <div class="position-fixed" style="margin-top: 12vh; width: 100%">
          <div class="aditionalServicesMainText"
               style="text-align: center; margin-top: 3vh">
            {{ "Corporate_training_and_certification" | translate }}
          </div>
          <div style="margin-left: 10vw; margin-top: 6vh">
            <p class="aditionalServicesText mb-0"
               style="margin-top: 3.14vh; width: 48vw">
              {{ "Training_under_the_PRINCE2_Foundation_and" | translate }}
            </p>
            <ol class="pl-3 mb-0" style="margin-top: 2vh">
              <li class="aditionalServicesText py-1"
                  style="line-height: 1.125vh">
                {{ "Online_training" | translate }}
              </li>
              <li class="aditionalServicesText py-1"
                  style="line-height: 1.125vh">
                {{ "Remote_training" | translate }}
              </li>
              <li class="aditionalServicesText py-1"
                  style="line-height: 1.125vh">
                {{ "Offline_training" | translate }}
              </li>
            </ol>

            <div style="">
              <p class="aditionalServicesText" style="margin-top: 3.88vh">
                <strong>{{ "Online_training" | translate }}</strong>
                {{ "Online_training_is" | translate }}
              </p>
              <p class="aditionalServicesText">
                <strong>{{ "With_remote_training" | translate }}</strong>
                {{ "the_trainer_conducts" | translate }}<br />
                {{ "Listeners_are_connected" | translate }}<br />
                {{ "The_advantage_of_this" | translate }} <br />{{
                  "between_the_listener" | translate
                }}<br />
                {{ "A_similar_method" | translate }}<br />
                {{ "cities_or_offices" | translate }}
              </p>
              <p class="aditionalServicesText">
                <strong>{{ "Offline_training" | translate }}</strong>
                {{ "is_a_classic_way" | translate }} <br />
                {{ "presence_of_a_trainer" | translate }} <br />
                {{ "of_the_Customer" | translate }}
              </p>
              <p class="aditionalServicesText">
                {{
                  "The_PRINCE2_Foundation_and_PRINCE2_Practitioner_1"
                    | translate
                }}
                <br />
                {{ "online_or_in_paper" | translate }}
              </p>
            </div>

            <div style="margin-top: 5.18vh">
              <p class="textGray">
                <a href="#getaply" class="zayavkaText">
                  {{
                  "Fill_out_the_application" | translate
                  }}
                </a>
                {{ "and_our_manager" | translate }}<br />
                {{ "the_training" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <img class="position-absolute h-100 w-100"
           src="/images/addiThirdBg.svg"
           alt="thirdBg"
           style="object-fit: fill" />
      <div class="home-container" style="">
        <div class="position-fixed" style="margin-top: 12vh; width: 100%">
          <div class="aditionalServicesMainText"
               style="text-align: center; margin-top: 3vh">
            {{ "Implementation_of_the" | translate }}
          </div>
          <div style="margin-left: 10vw; margin-top: 6vh">
            <p class="aditionalServicesText mb-0"
               style="margin-top: 3.14vh; width: 48vw">
              {{ "The_service_includes_the_development" | translate }}
            </p>

            <p class="aditionalServicesText"
               style="color: #2e5b5f; font-weight: bold; margin-top: 2vh">
              {{ "As_a_result_of_the_provision" | translate }}
            </p>

            <ul class="pl-3 mb-0" style="margin-top: 2vh">
              <li class="aditionalServicesText py-1"
                  style="line-height: 1.125vh">
                {{ "Regulations_and_procedures" | translate }}
              </li>
              <li class="aditionalServicesText py-1"
                  style="line-height: 1.125vh">
                {{ "Organizational_structure" | translate }}
              </li>
              <li class="aditionalServicesText py-1"
                  style="line-height: 1.125vh">
                {{ "Roles_descriptions" | translate }}
              </li>
              <li class="aditionalServicesText py-1"
                  style="line-height: 1.125vh">
                {{ "Job_descriptions" | translate }}
              </li>
              <li class="aditionalServicesText py-1"
                  style="line-height: 1.125vh">
                {{ "Document_templates" | translate }}
              </li>
              <li class="aditionalServicesText py-1"
                  style="line-height: 1.125vh">
                {{ "Report_templates" | translate }}
              </li>
            </ul>

            <div style="margin-top: 19.53vh">
              <p class="textGray">
                <a href="#getaply" class="zayavkaText">
                  {{
                  "Fill_out_the_application" | translate
                  }}
                </a>
                {{ "to_receive_a" | translate }}<br />
                {{ "of_the_PRINCE2" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <img class="position-absolute h-100 w-100"
           src="/images/addiFourBg.svg"
           alt="fourBg"
           style="object-fit: fill" />
      <div class="home-container" style="">
        <div class="position-fixed" style="margin-top: 12vh; width: 100%">
          <div class="aditionalServicesMainText"
               style="text-align: center; margin-top: 3vh">
            {{ "Implementation_of" | translate }}
          </div>
          <div style="margin-left: 10vw">
            <p class="aditionalServicesText mb-0" style="margin-top: 2.14vh">
              {{ "The_information_system" | translate }}<br />
              {{ "is_designed" | translate }}
            </p>
            <p class="aditionalServicesText mb-0" style="margin-top: 2.14vh">
              {{ "The_Information" | translate }}<br />
              {{ "automation_of" | translate }}<br />
              {{ "used_both" | translate }}<br />
              {{ "their_projects" | translate }}<br />{{
                "methodology" | translate
              }}
            </p>
            <p class="aditionalServicesText mb-0" style="margin-top: 2.14vh">
              {{ "The_system_is" | translate }}<br />{{
                "to_prioritize_and" | translate
              }}<br />
              {{ "is_a_centralized" | translate }}<br />
              {{ "projects_that_exist" | translate }}<br />{{
                "the_System" | translate
              }}
            </p>
            <div class="d-flex">
              <div>
                <p class="aditionalServicesText"
                   style="color: #2e5b5f; font-weight: bold; margin-top: 2vh">
                  {{ "Functional_areas" | translate }}<br />
                  {{ "Portfolio_management" | translate }}<br />
                  {{ "project_environment" | translate }}
                </p>

                <ul class="pl-3 mb-0" style="margin-top: 2vh">
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{ "Collecting_project_ideas" | translate }}
                  </li>
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{ "Project_Management" | translate }}
                  </li>
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{ "Program_Management" | translate }};
                  </li>
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{ "Project_Portfolio_Management" | translate }}
                  </li>
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{
                      "Development_of_project_management_expertise" | translate
                    }}
                  </li>
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{ "Resource_planning" | translate }}
                  </li>
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{ "Realization_of_benefits" | translate }}
                  </li>
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{ "Reporting_across_all_functional_areas" | translate }}
                  </li>
                </ul>
              </div>

              <div class="" style="margin-left: 10vw">
                <p class="aditionalServicesText"
                   style="color: #2e5b5f; font-weight: bold; margin-top: 2vh">
                  {{ "The_benefits_of" | translate }}
                </p>

                <ul class="pl-3 mb-0" style="margin-top: 2vh">
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{ "Increasing_the" | translate }}
                  </li>
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{ "Concentration_on_priority" | translate }}
                  </li>
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{ "Build_management_expertise" | translate }}
                  </li>
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{ "Using_the_resources" | translate }}
                  </li>
                  <li class="aditionalServicesText py-1"
                      style="line-height: 1.125vh">
                    {{ "Implementation_of_a" | translate }}
                  </li>
                </ul>
              </div>
            </div>

            <div style="margin-top: 3vh">
              <p class="textGray">
                <a href="#getaply" class="zayavkaText">
                  {{
                  "Fill_out_the_application" | translate
                  }}
                </a>
                {{ "to_receive_a_proposal" | translate }}<br />
                {{ "System_for_project" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="home-container" fxLayout="column" fxFlexFill>
        <div fxFlex="76.45"
             fxLayout="column"
             fxLayoutAlign="center stretch"
             class="w-100">
          <div class="borderInput mrg-right mrg-left text-center"
               style="margin-top: 8.12vh">
            <div class="aditionalServicesMainText mb-3"
                 style="text-align: center">
              {{ "Application" | translate }}
            </div>
            <form name="requestForm"
                  [formGroup]="requestForm"
                  fxLayout="row"
                  class="text-left"
                  style="align-items: end; margin-left: 1vw; margin-right: 1vw">
              <div fxFlex="33" fxLayout="column" style="padding-right: 2vw">
                <div class="reg-label">{{ "Company_name" | translate }}</div>
                <mat-form-field class="w-100"
                                floatLabel="never"
                                appearance="outline">
                  <input class="clientInfo"
                         formControlName="companyName"
                         [(ngModel)]="model.companyName"
                         floatLabel="never"
                         matInput />
                </mat-form-field>
                <div class="reg-label">
                  {{ "Name" | translate }} <span class="red-star">*</span>
                </div>
                <mat-form-field class="w-100"
                                floatLabel="never"
                                appearance="outline">
                  <input class="clientInfo"
                         formControlName="name"
                         [(ngModel)]="model.firstName"
                         floatLabel="never"
                         required
                         matInput />
                  <mat-error class="no-margin"
                             *ngIf="checkFormError('name', 'required')">
                    {{ "must_input" | translate }}
                  </mat-error>
                </mat-form-field>
                <div class="reg-label">{{ "Surname" | translate }}</div>
                <mat-form-field class="w-100"
                                floatLabel="never"
                                appearance="outline">
                  <input class="clientInfo"
                         formControlName="surname"
                         [(ngModel)]="model.lastName"
                         floatLabel="never"
                         matInput />
                </mat-form-field>
              </div>
              <div fxFlex="33" fxLayout="column" style="">
                <div class="reg-label">
                  {{ "Phone_number" | translate }}
                  <span class="red-star">*</span>
                </div>
                <mat-form-field class="w-100"
                                floatLabel="never"
                                appearance="outline">
                  <input type="tel"
                         prefix="{{ prefix }}"
                         mask=" 000 000 00 00"
                         [showMaskTyped]="true"
                         placeHolderCharacter="_"
                         name="phone_number"
                         #pnumber
                         aria-describedby="name"
                         formControlName="phoneNumber"
                         floatLabel="never"
                         matInput
                         class="phone-input w-100"
                         [(ngModel)]="phone_number"
                         placeholder="+7 (___) ___ __ __" />
                  <mat-error class="no-margin"
                             *ngIf="checkFormError('phoneNumber', 'required')">
                    {{ "must_input" | translate }}
                  </mat-error>
                </mat-form-field>

                <div class="reg-label">{{ "E-mail" | translate }}</div>
                <mat-form-field class="w-100"
                                floatLabel="never"
                                appearance="outline">
                  <input class="clientInfo"
                         formControlName="email"
                         [(ngModel)]="model.emailAddress"
                         floatLabel="never"
                         matInput />
                </mat-form-field>
                <div class="reg-label">
                  {{ "Application_subject" | translate }}
                  <span class="red-star">*</span>
                </div>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select formControlName="subject"
                              [(ngModel)]="selectedValue"
                              required
                              matInput
                              floatLabel="never">
                    <mat-option *ngFor="let item of subjects" [value]="item">
                      {{ item | translate }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="no-margin"
                             *ngIf="checkFormError('subject', 'required')">
                    {{ "must_input" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="33" fxLayout="column" style="margin-left: 2vw">
                <div class="reg-label">{{ "Comment_1" | translate }}</div>
                <mat-form-field class="w-100" fxFlex appearance="outline">
                  <textarea matInput
                            [(ngModel)]="model.text"
                            formControlName="comment"
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="4"
                            cdkAutosizeMaxRows="4"></textarea>
                </mat-form-field>
              </div>
            </form>
            <button class="px-0 buttonstyle"
                    (click)="sendApplication()"
                    mat-button
                    color="accent"
                    style="width: 11.19vw; font-size: 1.125em">
              {{ "send" | translate }}
            </button>
          </div>
        </div>

        <div class="ab-footer" fxLayout="row" fxFlex="25.55">
          <div fxFlex="25">
            <div fxLayout="column" fxFlexFill>
              <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 12em;" />
              <img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />
              <div fxFlex fxLayoutAlign="start center">
                <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
        <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
                <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
                <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />
                <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard" />
                <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa" />

              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex="20">
            <div fxLayout="column" fxFlexFill>

              <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" (click)="goDown1()">{{'Courses' | translate}}</a>
              <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" (click)="goDown2()">{{'Education_process' | translate}}</a>
              <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" (click)="goDown3()">{{'Comments' | translate}}</a>
              <a fxFlex="30" class="mat-title footer-text" (click)="goDown4()">{{'About_us' | translate}}</a>

            </div>



          </div>
          <div fxLayout="column" fxFlex="30">
            <div fxLayout="column" fxFlexFill>
              <a fxFlex="20" class="mat-title footer-text" routerLink="/additional-services" style="margin-bottom: 0.55vh;">{{'Additional_services' | translate}}</a>
              <a *ngIf="currentCountry==='Россия'" fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="margin-bottom: 1.20vh;">{{'support' | translate}}</a>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/payment-rules">{{'Pravila_Payment' | translate}}</a>
              </div>
              <div *ngIf="currentCountry==='Россия'">
                <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/oferta">{{'public_offer_1' | translate}}</a>
              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh; color: white !important;" routerLink="/ofertakz">{{'public_offer_1' | translate}}</a>
              </div>
              <div *ngIf="currentCountry==='Россия'">
                <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacy">{{'Personal_data_processing_policy' | translate}}</a>
              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacykz">{{'Personal_data_processing_policy' | translate}}</a>
              </div>

            </div>
          </div>

          <div fxLayout="column" fxFlex="30">
            <div fxLayout="column" fxFlexFill>
              <div *ngIf="currentCountry==='Россия'">
                <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addressru' | translate}}</p>
              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addresskz' | translate}}</p>
              </div>
              <div *ngIf="currentCountry==='Россия'">
                <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numru' | translate}}</p>
              </div>
              <!--<div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numkz' | translate}}</p>
              </div>-->
              <a fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<div class="d-none d-lg-inline" *ngIf="!isBrowser">
    <app-courses-toolbar (sidenavToggle)="sidenav.toggle()"></app-courses-toolbar>
    <div>Управление проектами, Стандарт PRINCE2, Методология PRINCE2, Управление проектами PRINCE2, Методы управления проектами, Стандарты управления проектами, Основы управления проектами, Проектный менеджмент, Портфолио менеджмент,  Управление проектной средой, Управление портфелем проектов, </div>
    <div fullpage
         id="servicesPage"
         [options]="config"
         (ref)="getRef($event)"
         #servicesPage>
        <div class="section">
            <img class="position-absolute h-100 w-100"
                 src="/images/addiFirstBg.svg"
                 alt="addiFirstBg"
                 style="object-fit: fill" />
            <div class="home-container" style="">
                <div class="position-fixed"
                     style="width: 46.63vw; margin-top: 18vh; margin-left: 10vw">
                    <div class="aditionalServicesMainText" style="margin-top: 6.6vh">
                        {{ "Additional_services" | translate }}
                    </div>
                    <p class="aditionalServicesText mb-0"
                       style="margin-top: 4.07vh; width: 32vw">
                        {{ "In_addition_to_online_training_in" | translate }}
                    </p>
                    <div class="d-flex" style="align-items: center; margin-top: 4.35vh">
                        <img src="/images/addserviceArrowRight.svg" alt="arrowRight" />
                        <a class="aditionalServicesBold pl-3" href="#corp">
                            {{
              "Corporate_training_and_ertification" | translate
                            }}
                        </a>
                    </div>
                    <div class="d-flex" style="align-items: center; margin-top: 4.35vh">
                        <img src="/images/addserviceArrowRight.svg" alt="arrowRight" />
                        <a class="aditionalServicesBold pl-3" href="#methodology">
                            {{
              "Implementation_of_the_PRINCE2_methodology" | translate
                            }}
                        </a>
                    </div>
                    <div class="d-flex" style="align-items: center; margin-top: 3.7vh">
                        <img src="/images/addserviceArrowRight.svg" alt="arrowRight" />
                        <a class="aditionalServicesBold pl-3" href="#informationsystem">
                            {{
              "Implementation_of_an_Information" | translate
                            }}
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <img class="position-absolute h-100 w-100"
                 src="/images/addiSecondBg.svg"
                 alt="secondBg"
                 style="object-fit: fill" />
            <div class="home-container" style="">
                <div class="position-fixed" style="margin-top: 12vh; width: 100%">
                    <div class="aditionalServicesMainText"
                         style="text-align: center; margin-top: 3vh">
                        {{ "Corporate_training_and_certification" | translate }}
                    </div>
                    <div style="margin-left: 10vw; margin-top: 6vh">
                        <p class="aditionalServicesText mb-0"
                           style="margin-top: 3.14vh; width: 48vw">
                            {{ "Training_under_the_PRINCE2_Foundation_and" | translate }}
                        </p>
                        <ol class="pl-3 mb-0" style="margin-top: 2vh">
                            <li class="aditionalServicesText py-1"
                                style="line-height: 1.125vh">
                                {{ "Online_training" | translate }}
                            </li>
                            <li class="aditionalServicesText py-1"
                                style="line-height: 1.125vh">
                                {{ "Remote_training" | translate }}
                            </li>
                            <li class="aditionalServicesText py-1"
                                style="line-height: 1.125vh">
                                {{ "Offline_training" | translate }}
                            </li>
                        </ol>

                        <div style="">
                            <p class="aditionalServicesText" style="margin-top: 3.88vh">
                                <strong>{{ "Online_training" | translate }}</strong>
                                {{ "Online_training_is" | translate }}
                            </p>
                            <p class="aditionalServicesText">
                                <strong>{{ "With_remote_training" | translate }}</strong>
                                {{ "the_trainer_conducts" | translate }}<br />
                                {{ "Listeners_are_connected" | translate }}<br />
                                {{ "The_advantage_of_this" | translate }} <br />{{
                  "between_the_listener" | translate
                                }}<br />
                                {{ "A_similar_method" | translate }}<br />
                                {{ "cities_or_offices" | translate }}
                            </p>
                            <p class="aditionalServicesText">
                                <strong>{{ "Offline_training" | translate }}</strong>
                                {{ "is_a_classic_way" | translate }} <br />
                                {{ "presence_of_a_trainer" | translate }} <br />
                                {{ "of_the_Customer" | translate }}
                            </p>
                            <p class="aditionalServicesText">
                                {{
                  "The_PRINCE2_Foundation_and_PRINCE2_Practitioner_1"
                    | translate
                                }}
                                <br />
                                {{ "online_or_in_paper" | translate }}
                            </p>
                        </div>

                        <div style="margin-top: 5.18vh">
                            <p class="textGray">
                                <a href="#getaply" class="zayavkaText">
                                    {{
                  "Fill_out_the_application" | translate
                                    }}
                                </a>
                                {{ "and_our_manager" | translate }}<br />
                                {{ "the_training" | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <img class="position-absolute h-100 w-100"
                 src="/images/addiThirdBg.svg"
                 alt="thirdBg"
                 style="object-fit: fill" />
            <div class="home-container" style="">
                <div class="position-fixed" style="margin-top: 12vh; width: 100%">
                    <div class="aditionalServicesMainText"
                         style="text-align: center; margin-top: 3vh">
                        {{ "Implementation_of_the" | translate }}
                    </div>
                    <div style="margin-left: 10vw; margin-top: 6vh">
                        <p class="aditionalServicesText mb-0"
                           style="margin-top: 3.14vh; width: 48vw">
                            {{ "The_service_includes_the_development" | translate }}
                        </p>

                        <p class="aditionalServicesText"
                           style="color: #2e5b5f; font-weight: bold; margin-top: 2vh">
                            {{ "As_a_result_of_the_provision" | translate }}
                        </p>

                        <ul class="pl-3 mb-0" style="margin-top: 2vh">
                            <li class="aditionalServicesText py-1"
                                style="line-height: 1.125vh">
                                {{ "Regulations_and_procedures" | translate }}
                            </li>
                            <li class="aditionalServicesText py-1"
                                style="line-height: 1.125vh">
                                {{ "Organizational_structure" | translate }}
                            </li>
                            <li class="aditionalServicesText py-1"
                                style="line-height: 1.125vh">
                                {{ "Roles_descriptions" | translate }}
                            </li>
                            <li class="aditionalServicesText py-1"
                                style="line-height: 1.125vh">
                                {{ "Job_descriptions" | translate }}
                            </li>
                            <li class="aditionalServicesText py-1"
                                style="line-height: 1.125vh">
                                {{ "Document_templates" | translate }}
                            </li>
                            <li class="aditionalServicesText py-1"
                                style="line-height: 1.125vh">
                                {{ "Report_templates" | translate }}
                            </li>
                        </ul>

                        <div style="margin-top: 19.53vh">
                            <p class="textGray">
                                <a href="#getaply" class="zayavkaText">
                                    {{
                  "Fill_out_the_application" | translate
                                    }}
                                </a>
                                {{ "to_receive_a" | translate }}<br />
                                {{ "of_the_PRINCE2" | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <img class="position-absolute h-100 w-100"
                 src="/images/addiFourBg.svg"
                 alt="fourBg"
                 style="object-fit: fill" />
            <div class="home-container" style="">
                <div class="position-fixed" style="margin-top: 12vh; width: 100%">
                    <div class="aditionalServicesMainText"
                         style="text-align: center; margin-top: 3vh">
                        {{ "Implementation_of" | translate }}
                    </div>
                    <div style="margin-left: 10vw">
                        <p class="aditionalServicesText mb-0" style="margin-top: 2.14vh">
                            {{ "The_information_system" | translate }}<br />
                            {{ "is_designed" | translate }}
                        </p>
                        <p class="aditionalServicesText mb-0" style="margin-top: 2.14vh">
                            {{ "The_Information" | translate }}<br />
                            {{ "automation_of" | translate }}<br />
                            {{ "used_both" | translate }}<br />
                            {{ "their_projects" | translate }}<br />{{
                "methodology" | translate
                            }}
                        </p>
                        <p class="aditionalServicesText mb-0" style="margin-top: 2.14vh">
                            {{ "The_system_is" | translate }}<br />{{
                "to_prioritize_and" | translate
                            }}<br />
                            {{ "is_a_centralized" | translate }}<br />
                            {{ "projects_that_exist" | translate }}<br />{{
                "the_System" | translate
                            }}
                        </p>
                        <div class="d-flex">
                            <div>
                                <p class="aditionalServicesText"
                                   style="color: #2e5b5f; font-weight: bold; margin-top: 2vh">
                                    {{ "Functional_areas" | translate }}<br />
                                    {{ "Portfolio_management" | translate }}<br />
                                    {{ "project_environment" | translate }}
                                </p>

                                <ul class="pl-3 mb-0" style="margin-top: 2vh">
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{ "Collecting_project_ideas" | translate }}
                                    </li>
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{ "Project_Management" | translate }}
                                    </li>
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{ "Program_Management" | translate }};
                                    </li>
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{ "Project_Portfolio_Management" | translate }}
                                    </li>
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{
                      "Development_of_project_management_expertise" | translate
                                        }}
                                    </li>
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{ "Resource_planning" | translate }}
                                    </li>
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{ "Realization_of_benefits" | translate }}
                                    </li>
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{ "Reporting_across_all_functional_areas" | translate }}
                                    </li>
                                </ul>
                            </div>

                            <div class="" style="margin-left: 10vw">
                                <p class="aditionalServicesText"
                                   style="color: #2e5b5f; font-weight: bold; margin-top: 2vh">
                                    {{ "The_benefits_of" | translate }}
                                </p>

                                <ul class="pl-3 mb-0" style="margin-top: 2vh">
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{ "Increasing_the" | translate }}
                                    </li>
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{ "Concentration_on_priority" | translate }}
                                    </li>
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{ "Build_management_expertise" | translate }}
                                    </li>
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{ "Using_the_resources" | translate }}
                                    </li>
                                    <li class="aditionalServicesText py-1"
                                        style="line-height: 1.125vh">
                                        {{ "Implementation_of_a" | translate }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div style="margin-top: 3vh">
                            <p class="textGray">
                                <a href="#getaply" class="zayavkaText">
                                    {{
                  "Fill_out_the_application" | translate
                                    }}
                                </a>
                                {{ "to_receive_a_proposal" | translate }}<br />
                                {{ "System_for_project" | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="home-container" fxLayout="column" fxFlexFill>
                <div fxFlex="76.45"
                     fxLayout="column"
                     fxLayoutAlign="center stretch"
                     class="w-100">
                    <div class="borderInput mrg-right mrg-left text-center"
                         style="margin-top: 8.12vh">
                        <div class="aditionalServicesMainText mb-3"
                             style="text-align: center">
                            {{ "Application" | translate }}
                        </div>
                        <form name="requestForm"
                              [formGroup]="requestForm"
                              fxLayout="row"
                              class="text-left"
                              style="align-items: end; margin-left: 1vw; margin-right: 1vw">
                            <div fxFlex="33" fxLayout="column" style="padding-right: 2vw">
                                <div class="reg-label">{{ "Company_name" | translate }}</div>
                                <mat-form-field class="w-100"
                                                floatLabel="never"
                                                appearance="outline">
                                    <input class="clientInfo"
                                           formControlName="companyName"
                                           [(ngModel)]="model.companyName"
                                           floatLabel="never"
                                           matInput />
                                </mat-form-field>
                                <div class="reg-label">
                                    {{ "Name" | translate }} <span class="red-star">*</span>
                                </div>
                                <mat-form-field class="w-100"
                                                floatLabel="never"
                                                appearance="outline">
                                    <input class="clientInfo"
                                           formControlName="name"
                                           [(ngModel)]="model.firstName"
                                           floatLabel="never"
                                           required
                                           matInput />
                                    <mat-error class="no-margin"
                                               *ngIf="checkFormError('name', 'required')">
                                        {{ "must_input" | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <div class="reg-label">{{ "Surname" | translate }}</div>
                                <mat-form-field class="w-100"
                                                floatLabel="never"
                                                appearance="outline">
                                    <input class="clientInfo"
                                           formControlName="surname"
                                           [(ngModel)]="model.lastName"
                                           floatLabel="never"
                                           matInput />
                                </mat-form-field>
                            </div>
                            <div fxFlex="33" fxLayout="column" style="">
                                <div class="reg-label">
                                    {{ "Phone_number" | translate }}
                                    <span class="red-star">*</span>
                                </div>
                                <mat-form-field class="w-100"
                                                floatLabel="never"
                                                appearance="outline">
                                    <input type="tel"
                                           prefix="{{ prefix }}"
                                           mask=" 000 000 00 00"
                                           [showMaskTyped]="true"
                                           placeHolderCharacter="_"
                                           name="phone_number"
                                           #pnumber
                                           aria-describedby="name"
                                           formControlName="phoneNumber"
                                           floatLabel="never"
                                           matInput
                                           class="phone-input w-100"
                                           [(ngModel)]="phone_number"
                                           placeholder="+7 (___) ___ __ __" />
                                    <mat-error class="no-margin"
                                               *ngIf="checkFormError('phoneNumber', 'required')">
                                        {{ "must_input" | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <div class="reg-label">{{ "E-mail" | translate }}</div>
                                <mat-form-field class="w-100"
                                                floatLabel="never"
                                                appearance="outline">
                                    <input class="clientInfo"
                                           formControlName="email"
                                           [(ngModel)]="model.emailAddress"
                                           floatLabel="never"
                                           matInput />
                                </mat-form-field>
                                <div class="reg-label">
                                    {{ "Application_subject" | translate }}
                                    <span class="red-star">*</span>
                                </div>
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-select formControlName="subject"
                                                [(ngModel)]="selectedValue"
                                                required
                                                matInput
                                                floatLabel="never">
                                        <mat-option *ngFor="let item of subjects" [value]="item">
                                            {{ item | translate }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error class="no-margin"
                                               *ngIf="checkFormError('subject', 'required')">
                                        {{ "must_input" | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="33" fxLayout="column" style="margin-left: 2vw">
                                <div class="reg-label">{{ "Comment_1" | translate }}</div>
                                <mat-form-field class="w-100" fxFlex appearance="outline">
                                    <textarea matInput
                                              [(ngModel)]="model.text"
                                              formControlName="comment"
                                              cdkTextareaAutosize
                                              cdkAutosizeMinRows="4"
                                              cdkAutosizeMaxRows="4"></textarea>
                                </mat-form-field>
                            </div>
                        </form>
                        <button class="px-0 buttonstyle"
                                (click)="sendApplication()"
                                mat-button
                                color="accent"
                                style="width: 11.19vw; font-size: 1.125em">
                            {{ "send" | translate }}
                        </button>
                    </div>
                </div>

                <div class="ab-footer" fxLayout="row" fxFlex="25.55">
                    <div fxFlex="25">
                        <div fxLayout="column" fxFlexFill>
                            <img src="/images/ComProductsLogo.svg"
                                 alt="comproductsLogo"
                                 style="width: 12em" />
                            <img src="/images/axelos.svg"
                                 alt="axelosLogo"
                                 style="width: 12em; padding-top: 2.03vh; padding-bottom: 2.03vh" />
                            <div fxFlex fxLayoutAlign="start end">
                                <!--<img src="/images/facebook.svg"
                                     alt="facebookLogo"
                                     class="footer-contact-img" />
                                <img src="/images/instagram.svg"
                                     alt="instaLogo"
                                     class="footer-contact-img" />-->
                                <img src="/images/vk.svg"
                                     alt="vkLogo"
                                     class="footer-contact-img" />
                                <img src="/images/telegram.svg"
                                     alt="telegramLogo"
                                     class="footer-contact-img" />
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="20">
                        <div fxLayout="column" fxFlexFill>
                            <a fxFlex="10"
                               class="mat-title footer-text"
                               (click)="goDown1()">{{ "Courses" | translate }}</a>
                            <a fxFlex="20"
                               class="mat-title footer-text"
                               (click)="goDown2()"
                               style="margin-bottom: 1.2vh">{{ "Education_process" | translate }}</a>
                            <a fxFlex="10"
                               class="mat-title footer-text"
                               (click)="goDown3()">{{ "Comments" | translate }}</a>
                            <a fxFlex="10"
                               class="mat-title footer-text"
                               (click)="goDown4()">{{ "About_us" | translate }}</a>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="30">
                        <div fxLayout="column" fxFlexFill>
                            <a fxFlex="20"
                               class="mat-title footer-text"
                               href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"
                               style="margin-bottom: 1.2vh">{{ "support" | translate }}</a>
                            <a fxFlex="20" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" innerHTML="{{'public_offer_1' | translate}}"></a>
                            <a fxFlex="30"
                               class="mat-title footer-text ofertaCss" style="color: white !important;" innerHTML="{{'Personal_data_processing_policy' | translate}}"></a>
                        </div>
                    </div>

                    <div fxLayout="column" fxFlex="30">
                        <div fxLayout="column" fxFlexFill>
                            <p class="mat-title footer-text" style="margin-bottom: 1.2vh">
                                <img src="/images/locationSmall.svg"
                                     alt="locationSmall"
                                     class=""
                                     style="padding-right: 0.625vw" />{{ "address" | translate }}
                            </p>
                            <p class="mat-title footer-text" style="margin-bottom: 1.2vh">
                              <img src="/images/phoneSmall.svg"
                                   alt="phoneSmall"
                                   class=""
                                   style="padding-right: 0.625vw" />{{'phone_num' | translate}}
                            </p>
                            <a fxFlex="20"
                               class="mat-title footer-text"
                               href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8">
                                <img src="/images/mailSmall.svg"
                                     alt="mailLogo"
                                     class=""
                                     style="padding-right: 0.625vw" />info@comproducts.ru
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>

<mat-menu #langMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let language of translate.getLangs()"
    (click)="switchLang(language)"
  >
    <span>{{ language | translate }}</span>
  </button>
</mat-menu>

<mat-menu #contactMenu="matMenu">
  <button mat-menu-item (click)="cabinet()">
    <span>{{ "cabinet" | translate }}</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <span>{{ "logout" | translate }}</span>
  </button>
</mat-menu>
