<div class="d-none d-lg-inline" *ngIf="!isBrowser">
        <div class="section server-section-0">
            <img class="position-absolute h-100 w-100" src="/images/main_bg.svg" alt="mainBg" />
            <div class="home-container">
                <pre class="mat-display-4 mc-1 overflow-hidden position-absolute" innerHTML="{{'mc-1' | translate}}"></pre>
                <!--<div>Курсы PRINCE2</div>-->
                <pre class="mat-display-2 mc-1-1 overflow-hidden position-absolute" innerHTML="{{'mc-1-1' | translate}}"></pre>
                <!--<div>Онлайн курсы PRINCE2</div>-->
                <pre class="mat-body-1 mc-1-2 overflow-hidden position-absolute" innerHTML="{{'mc-1-2' | translate}}"></pre>
                <!--<div>PRINCE2 на русском</div>-->
            </div>
        </div>


        <div class="section server-section-1" id="courses-container">
            <img class="position-absolute h-100 w-100" src="/images/courses_bg.svg" alt="coursesBg" />
            <div class="mat-display-3 main-title position-absolute">{{'Courses' | translate}}</div>
            <div class="slide">
                <div class="home-container container-padding">
                    <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
                        <div class="course-container">
                            <img class="course-image" src="/images/foundation.svg" alt="foundationLogo" />
                            <pre class="mat-display-3 overflow-hidden course-title position-absolute">PRINCE2
FOUNDATION</pre>
                           
                            <div>
                              Онлайн-обучение и экзамен PRINCE2, Обучение PRINCE2, Курсы PRINCE2, Удаленное обучение PRINCE2, Дистанционное обучение PRINCE2, PRINCE2 Foundation на русском, Экзамен PRINCE2, Сертификация PRINCE2, Метод  PRINCE2, PRINCE2 Foundation, PRINCE2 в России, Видеоуроки PRINCE2, Методология PRINCE2, Axelos
                            </div>
                            <pre class="mat-body-1 font-size-middle overflow-hidden course-description" innerHTML="{{'foundation' | translate}}"></pre>
                            <a class="my-auto course-description" routerLink="/foundation-ru">{{'more_delails' | translate}}</a>
                            <div class="course-button-container">
                                <button class="mat-body-1 b-secondary b-course-details mx-auto"
                                        (click)="buyCourse('443BC788-7D51-EB11-80E1-00155D784603')"
                                        mat-button color="accent">
                                    {{'take_training' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section server-section-2">
            <img class="position-absolute h-100 w-100" src="/images/education_bg.svg" alt="educationBg" />
            <div class="mat-display-3 main-title position-absolute">{{'Education_process' | translate}}</div>
            
            <div class="slide">
                <div class="home-container" fxFlexFill fxLayout="column" fxLayoutAlign="space-evenly center">
                    <pre class="ed-title mat-body overflow-hidden position-absolute" innerHTML="{{'ed-1' | translate}}"></pre>
                    <div *ngIf="!isLoading" class="ed-video-container">
                    </div>
                    <div>
                        <img src="/images/left.svg" onclick="" alt="left" />
                        <img src="/images/right.svg" onclick="" alt="right" />
                    </div>
                </div>
            </div>
            <div class="slide">
                <div class="home-container" fxFlexFill fxLayout="column" fxLayoutAlign="space-evenly center">
                    <pre class="ed-title mat-body overflow-hidden position-absolute" innerHTML="{{'ed-1' | translate}}"></pre>
                    <div *ngIf="!isLoading" class="ed-video-container">
                    </div>
                    <div>
                        <img src="/images/left.svg" onclick="" alt="left" />
                        <img src="/images/right.svg" onclick="" alt="right" />
                    </div>
                </div>
            </div>
        </div>
        <div class="section server-section-3">
            <img class="position-absolute h-100 w-100" src="/images/comments_bg.svg" alt="commentsBg" />
            <div class="home-container" fxFlexFill>
                
                <div class="mat-display-3 main-title position-absolute">{{'Comments' | translate}}</div>
                <div class="comments-button-bar">
                    <img src="/images/left.svg" alt="left" onclick="document.getElementsByClassName('fa-angle-left')[0].click();" />
                    <img src="/images/right.svg" alt="right" onclick="document.getElementsByClassName('fa-angle-right')[0].click();" />
                </div>
                <div fxLayout="row"
                     fxFlexFill
                     fxLayoutAlign="stretch center">
                    <div fxFlex class="comments-container">
                        <owl-carousel-o [options]="customOptions" (translated)="getPassedData($event)">
                            <ng-container *ngFor="let feedback of comments">
                                <ng-template class="slide" carouselSlide [id]="feedback.id">
                                    <mat-card class="example-header-image comments mat-elevation-z8 m-3">
                                        <mat-card-header class="align-items-center">
                                            <div mat-card-avatar class="user-image"></div>
                                            <mat-card-title class="user-name">{{feedback?.contact?.firstName}} {{feedback?.contact?.lastName}}</mat-card-title>
                                            <mat-card-subtitle class="sub-text mat-body-2">{{feedback?.feedBackDate | localizedDate:'longDate' }}</mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-content>
                                            <div class="row">
                                                <div class="col-auto mat-body-2 sub-text">{{'Teaching' | translate}}<ngb-rating class="col-2" [max]="5" [starTemplate]="t" [(rate)]="feedback.teachingQualityRate" [readonly]="true"></ngb-rating></div>
                                                <div class="col-auto mx-auto mat-body-2 sub-text">{{'Educational_materials' | translate}}<ngb-rating class="col-2" [max]="5" [starTemplate]="t" [(rate)]="feedback.materialsQualityRate" [readonly]="true"></ngb-rating></div>
                                                <div class="col-auto mat-body-2 sub-text">{{ 'Learning_portal' | translate}}<ngb-rating class="col-2" [max]="5" [starTemplate]="t" [(rate)]="feedback.portalUsabilityRate" [readonly]="true"></ngb-rating></div>
                                            </div>
                                            <p class="mat-body comment-content">{{feedback.text}}</p>
                                        </mat-card-content>
                                    </mat-card>
                                    <!--img class="comments" [src]="slide.url" [alt]="slide.id" [title]="slide.id"-->
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
        <div class="section server-section-4">
            <img class="position-absolute h-100 w-100" src="/images/about_bg.svg" alt="aboutbg" />
            <div class="home-container" fxLayout="column" fxFlexFill>
                <div class="mat-display-3 main-title ab-title position-absolute" color="accent">{{'About_us' | translate}}</div>
                
                <div class="ab-container" fxFlex="66.45" fxLayout="row" fxLayoutAlign="space-between space-around">
                  <div fxFlex="68" fxLayout="column" style="z-index: 50 !important;" fxLayoutAlign="space-between stretch">
                    <pre class="ab-1 mat-body" innerHTML="{{'about' | translate}}"></pre>
                    <div class="mat-display-1 ab-2 ab-title mt-auto">{{'about-2' | translate}}</div>
                    <a class="my-auto" routerLink="/additional-services">{{'Additional_services' | translate}}</a>
                    <p class="ab-1 mat-body">{{'Mop_text' | translate}}</p>
                  </div>
                    <div fxFlex="15" class="mrg-right" style="z-index: 50 !important;">
                        <img class="cert-img mx-auto" style="margin-top: 0;" src="/images/Group_56.svg" alt="group" />
                        <img class="cert-img mx-auto" src="/images/Group_57.svg" alt="group" />
                    </div>
                </div>
                <app-courses-footer style="z-index:1;"></app-courses-footer>
            </div>
        </div>
    </div>

<mat-sidenav-container class="d-sm-inline d-md-inline d-lg-none" style="width: 100%" *ngIf="!desktopMenu">
    <mat-sidenav #sidenav position="end" style="overflow-x:hidden;">
        <app-courses-navbar
      (sidenavClose)="sidenav.toggle()"
      (courses)="courses.scrollIntoView({ behavior: 'smooth', block: 'start' })"
      (education)="education.scrollIntoView({ behavior: 'smooth', block: 'start' })"
      (feedback)="feedback.scrollIntoView({ behavior: 'smooth', block: 'start' })"
      (aboutUs)="aboutus.scrollIntoView({ behavior: 'smooth', block: 'start' })"
    ></app-courses-navbar>
    </mat-sidenav>
    <mat-sidenav-content style="height: 100vh; overflow-x: hidden;">
      <!--(onCountryChange)="onCountryChange($event)"
      (sidenavToggle)="sidenav.toggle()"-->
      <app-courses-toolbar (sidenavToggle)="sidenav.toggle()"></app-courses-toolbar>
      <!--(onCountryChange)="onCountryChange($event)"-->
      <div>
        <div class="w-100 h-100">
          <picture>
            <source srcset="/images/mobile-bg-main-table.svg" type="image/svg+xml" media="(min-width:480px) and (orientation: landscape)" />
            <!--source srcset="/images/mobile-bg-main.svg" type="image/svg+xml" media="(max-width:480px)" /-->
            <img class="position-absolute portait-image" src="/images/mobile-bg-main-1.svg" alt="mainBg" style="height: 100%; width: 100%; object-fit: cover; top: 0;" />
          </picture>


          <div class="" style="z-index:50;position:relative;">

            <p class=" mobile-first table-first overflow-hidden" innerHTML="{{'mc-1' | translate}}"></p>
            <p class=" mobile-second table-second overflow-hidden" innerHTML="{{'mc-1-1' | translate}}"></p>
            <p class=" mobile-third table-third overflow-hidden" innerHTML="{{'mc-1-2' | translate}}"></p>

          </div>
        </div>
        <div class="" style="top:100vh;position:absolute;width: 100%;">
          <div class="" style="z-index:50 !important;margin-top:0;">
            <div id="courses" class="">
              <div #courses class="section" id="courses-container">
                <div class="main-title-table main-title-mobile">{{'Courses' | translate}}</div>
                <div class="slide course-container-table-2 mobile-container-2">
                  <div class="">
                    <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
                      <div class="course-container course-container-table course-container-mobile">
                        <img class="course-image course-image-mobile" src="/images/foundation.svg" alt="foundationLogo" />
                        <pre class=" overflow-hidden course-title-table course-title-mobile">PRINCE2 FOUNDATION</pre>
                        <pre class="course-description-text-table course-description course-description-mobile" innerHTML="{{'foundation' | translate}}"></pre>
                        <a class="my-auto course-additional-table course-additional-mobile course-description-mobile" routerLink="/foundation-ru">{{'more_delails' | translate}}</a>
                        <div *ngIf="currentCountry==='Россия'" class="btn-table">
                          <button class="buttonstyle-table btn425-mobile"
                                  (click)="buyCourse('443BC788-7D51-EB11-80E1-00155D784603')"
                                  mat-button color="accent">
                            {{'take_training' | translate}}
                          </button>
                        </div>

                        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" class="btn-table">
                          <button class="buttonstyle-table btn425-mobile"
                                  (click)="buyCourse('F6FACACB-5FB7-ED11-88B6-A92673B5996B')"
                                  mat-button color="accent">
                            {{'take_training' | translate}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="" style=" margin-top: 4vh;">
              <div class="position-absolute img-comment-mobile-top2 img-comment-table-top2 " style="">
                <img class="" src="/images/course-ed-process-img.svg" alt="botImg" />
              </div>
              <div id="education">
                <div #education class="main-title-table main-title-mobile-comment section" style="">{{'Education_process' | translate}}</div>
                <div class="">
                  <div class="table-container-comment mobile-container">
                    <pre class=" video-title-table video-title-mobile-text" innerHTML="{{'ed-1' | translate}}"></pre>
                    <div *ngIf="!isLoading" class="ed-video-container-mobile">
                      <owl-carousel-o *ngIf="!isResized" [options]="customOptionsMobileVideo">
                        <ng-container>
                          <ng-template class="slide" carouselSlide>
                            <vg-player>
                              <vg-controls [vgAutohide]="true" [vgAutohideTime]="5">
                                <vg-play-pause></vg-play-pause>
                                <vg-playback-button></vg-playback-button>
                                <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
                                <vg-scrub-bar>
                                  <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                                  <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                                </vg-scrub-bar>
                                <vg-mute></vg-mute>
                                <vg-volume></vg-volume>
                                <vg-fullscreen></vg-fullscreen>
                              </vg-controls>
                              <video [vgMedia]="master3" #master3 [vgMaster]="true" id="masterVideo3" preload="metadata">
                                <source src="/video/PRINCE2_FOUNDATION_RU_1.mp4" type="video/mp4">
                              </video>
                            </vg-player>
                          </ng-template>
                        </ng-container>
                        <ng-container>
                          <ng-template class="slide" carouselSlide>
                            <vg-player>
                              <vg-controls [vgAutohide]="true" [vgAutohideTime]="5">
                                <vg-play-pause></vg-play-pause>
                                <vg-playback-button></vg-playback-button>
                                <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
                                <vg-scrub-bar>
                                  <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                                  <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                                </vg-scrub-bar>
                                <vg-mute></vg-mute>
                                <vg-volume></vg-volume>
                                <vg-fullscreen></vg-fullscreen>
                              </vg-controls>
                              <video [vgMedia]="master4" #master4 id="masterVideo4" preload="metadata">
                                <source src="/video/PRINCE2_FOUNDATION_RU_11.mp4" type="video/mp4">
                              </video>
                            </vg-player>
                          </ng-template>
                        </ng-container>
                      </owl-carousel-o>
                    </div>
                    <div class="row">
                      <div class="col-12" style="text-align:center;">
                        <div class="">
                          <img src="/images/left.svg" onclick="document.getElementsByClassName('fa-angleVid-left')[0].click();" />
                          <img src="/images/right.svg" onclick="document.getElementsByClassName('fa-angleVid-right')[0].click();" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--<div class="slide">
            <div class="home-container" fxFlexFill fxLayout="column" fxLayoutAlign="space-evenly center">
              <pre class="ed-title mat-body overflow-hidden" innerHTML="{{'ed-1' | translate}}"></pre>
              <div *ngIf="!isLoading" class="ed-video-container">
                <owl-carousel-o [options]="customOptionsMobile" (translated)="getPassedData($event)">
                  <vg-player>
                    <vg-controls>
                      <vg-play-pause></vg-play-pause>
                      <vg-scrub-bar>
                        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                      </vg-scrub-bar>
                      <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
                      <vg-mute></vg-mute>
                      <vg-fullscreen></vg-fullscreen>
                    </vg-controls>
                    <video [vgMedia]="master1" #master1 id="masterVideo1" preload="auto">
                      <source src="/video/PRINCE2_FOUNDATION_RU_11.mp4" type="video/mp4">
                    </video>
                  </vg-player>
                </owl-carousel-o>
                <vg-player>
                  <vg-controls>
                    <vg-play-pause></vg-play-pause>
                    <vg-scrub-bar>
                      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                    </vg-scrub-bar>
                    <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
                    <vg-mute></vg-mute>
                    <vg-fullscreen></vg-fullscreen>
                  </vg-controls>
                  <video [vgMedia]="master1" #master1 id="masterVideo1" preload="auto">
                    <source src="/video/PRINCE2_FOUNDATION_RU_11.mp4" type="video/mp4">
                  </video>
                </vg-player>
              </div>
              <div>
                <img src="/images/left.svg" onclick="" alt="left" />
                <img src="/images/right.svg" onclick="" alt="right" />
              </div>
            </div>
          </div>-->
            </div>
            <div class="">
              <div class="position-absolute img-comment-mobile img-table-comment img-comment-mobile-top" style="">
                <img class=" w-100 h-100" src="/images/comment-mobile-img.svg" alt="botImg" />
              </div>
              <div id="comments">
                <div class="" fxFlexFill>
                  <div #feedback class="section main-title-table main-title-mobile" style=" margin-top: 4vh;">{{'Comments' | translate}}</div>
                  <div fxLayout=""
                       fxFlexFill
                       fxLayoutAlign="">
                    <div fxFlex class="">
                      <owl-carousel-o *ngIf="!isResized" [options]="customOptionsMobile" (translated)="getPassedData($event)">
                        <ng-container *ngFor="let feedback of comments">
                          <ng-template class="slide" carouselSlide [id]="feedback.id">
                            <mat-card class=" mobile-container-comment table-container-comment" style="">
                              <mat-card-header class="align-items-center">
                                <div mat-card-avatar class="img-mobile-avatar">
                                  <img class="img-mobile" src="/images/avatar-mobile-img.svg" />
                                </div>
                                <mat-card-title class="user-name-table user-name-mobile">{{feedback?.contact?.firstName}} {{feedback?.contact?.lastName}}</mat-card-title>
                                <mat-card-subtitle class="sub-text-table sub-text-mobile mat-body-2">{{feedback?.feedBackDate | localizedDate:'longDate' }}</mat-card-subtitle>
                              </mat-card-header>
                              <mat-card-content>
                                <div class="row">
                                  <div class="col-12 mat-body-2 sub-text-table sub-text-mobile">{{'Teaching' | translate}}<ngb-rating class="col-2 star-font-size-mobile" [max]="5" [starTemplate]="t" [(rate)]="feedback.teachingQualityRate" [readonly]="true"></ngb-rating></div>
                                  <div class="col-12 mx-auto mat-body-2 sub-text-table sub-text-mobile">{{'Educational_materials' | translate}}<ngb-rating class="col-2 star-font-size-mobile" [max]="5" [starTemplate]="t" [(rate)]="feedback.materialsQualityRate" [readonly]="true"></ngb-rating></div>
                                  <div class="col-12 mat-body-2 sub-text-table sub-text-mobile">{{ 'Learning_portal' | translate}}<ngb-rating class="col-2 star-font-size-mobile" [max]="5" [starTemplate]="t" [(rate)]="feedback.portalUsabilityRate" [readonly]="true"></ngb-rating></div>
                                </div>
                                <p class="mat-body  comment-text-table comment-text-mobile">{{feedback.text}}</p>
                              </mat-card-content>
                            </mat-card>
                            <!--img class="comments" [src]="slide.url" [alt]="slide.id" [title]="slide.id"-->
                          </ng-template>
                        </ng-container>
                      </owl-carousel-o>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12" style="text-align: center;">
                      <div class="comments-button-bar-table comments-button-bar-mobile " style="">
                        <img src="/images/left.svg" alt="left" onclick="document.getElementsByClassName('fa-angle-left')[0].click();" />
                        <img src="/images/right.svg" alt="right" onclick="document.getElementsByClassName('fa-angle-right')[0].click();" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="section">
              <div id="about">
                <div class="row">
                  <div class="col">
                    <div #aboutus class="section ab-title-mobile ab-title-table section" style=" margin-top: 4vh;" color="accent">{{'About_us' | translate}}</div>
                    <div class="col" style="z-index: 1;">
                      <div class="" style="z-index: 50 !important;">
                        <div *ngIf="currentCountry==='Россия'">
                          <pre class="course-description-text-table course-description-text-mobile">{{'aboutru' | translate}}</pre>
                        </div>
                        <div *ngIf="currentCountry==='Казахстан' || currentCountry==='Другая'">
                          <pre class="course-description-text-table course-description-text-mobile">{{'aboutkz' | translate}}</pre>
                        </div>
                        <div class="about-us-gold-table about-us-gold-mobile">{{'about-2' | translate}}</div>
                        <a class=" about-us-services-table about-us-services-mobile" routerLink="/additional-services">{{'Additional_services' | translate}}</a>
                        <p class="course-description-text-table course-description-text-mobile mt-3">{{'Mop_text' | translate}}</p>
                      </div>
                        <div class="col-12 px-0 img-margin-table img-margin-mobile" style="">
                          <div class="img-table img-mobile" style="z-index: 50 !important;">
                            <img class="img-table-width img-mobile-width" style="" src="/images/prince-training-org.svg" alt="group" />
                            <img class="img-table-width img-mobile-width" src="/images/prince-exam-org.svg" alt="group" />
                          </div>
                        </div>
                      </div>
                    <div class="position-absolute" style="right:0;bottom:0;">
                      <img class="img-table-aboutUs img-mobile-aboutUs" src="/images/main-bottom-image-mobile.svg" alt="botImg" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="ab-footer-mobile ab-footer-table" fxLayout="column" fxFlex="25.55">
                  <div fxFlex="21" style="">
                    <div fxLayout="column" fxFlexFill style="">
                      <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 30em;" />
                      <!--<img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />-->
                      <!--<div fxFlex fxLayoutAlign="start" style="padding-top: 1.85vh;">-->
                      <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
      <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
                      <!--<img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
      <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />-->
                      <!--</div>-->
                    </div>
                  </div>
                  <div fxLayout="column" class="" fxFlex="22" style="margin-left: 3.73vw; margin-bottom:2vh;">
                    <div fxLayout="column" class="pb-4" fxFlexFill>

                      <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="courses.scrollIntoView({ behavior: 'smooth', block: 'start' })" style="">{{'Courses' | translate}}</a>
                      <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="education.scrollIntoView({ behavior: 'smooth', block: 'start' })" style="">{{'Education_process' | translate}}</a>
                      <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="feedback.scrollIntoView({ behavior: 'smooth', block: 'start' })" style="">{{'Comments' | translate}}</a>
                      <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="aboutus.scrollIntoView({ behavior: 'smooth', block: 'start' })" style="">{{'About_us' | translate}}</a>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <div fxLayout="column" fxFlex="30" class="py-2" style="margin-left: 3.73vw;">
                    <div fxLayout="column" fxFlexFill>
                      <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" routerLink="/additional-services" style="">{{'Additional_services' | translate}}</a>
                      <a *ngIf="currentCountry==='Россия'" fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="">{{'support' | translate}}</a>
                      <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                        <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/payment-rules">{{'Pravila_Payment' | translate}}</a>
                      </div>
                      <div *ngIf="currentCountry==='Россия'">
                        <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table ofertaCss" routerLink="/oferta" style="">{{'public_offer_1' | translate}}</a>
                      </div>
                      <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                        <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table ofertaCss" routerLink="/ofertakz" style="">{{'public_offer_1' | translate}}</a>
                      </div>
                      <div *ngIf="currentCountry==='Россия'">
                        <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table mb-0 ofertaCss" style="color: white !important;" routerLink="/privacy">{{'Personal_data_processing_policy' | translate}}</a>

                      </div>
                      <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                        <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table mb-0 ofertaCss" style="color: white !important;" routerLink="/privacykz">{{'Personal_data_processing_policy' | translate}}</a>

                      </div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <div fxLayout="column" class="py-2" fxFlex="30" style="margin-left: 3.73vw;">
                    <div fxLayout="column" fxFlexFill>
                      <div *ngIf="currentCountry==='Россия'">
                        <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addressru' | translate}}</p>

                      </div>
                      <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                        <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addresskz' | translate}}</p>

                      </div>
                      <div *ngIf="currentCountry==='Россия'">
                        <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numru' | translate}}</p>

                      </div>
                      <!--<div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                        <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numkz' | translate}}</p>

                      </div>-->
                      <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" style="" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex="22" style="margin-left: 3.73vw;">
                    <div fxLayout="row" fxFlexFill>
                      <img src="/images/axelos.svg" class="axelos-img-mobile axelos-img-table" alt="axelosLogo" style="" />
                      <div fxFlex fxLayoutAlign="start center" style="align-items:center;margin-left: 10px;">
                        <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img-mobile footer-contact-img-table " />
  <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img-mobile footer-contact-img-table" />-->
                        <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img-mobile footer-contact-img-table" />
                        <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img-mobile footer-contact-img-table" />
                        <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard-mobile" />
                        <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa-mobile" />
                      </div>

                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<div *ngIf="isBrowser && desktopMenu">
  <!--<app-modal-country-select *ngIf="shouldDisplayModal"></app-modal-country-select>-->

  <app-courses-toolbar></app-courses-toolbar>

  <div fullpage id="fullpage" [options]="config" (ref)="getRef($event)" #fullpageRef>
    <div class="section">
      <img class="position-fixed" src="/images/main_bg.svg" alt="mainBg" style="height: inherit; width: 100%; object-fit: fill;" />
      <div class="home-container">
        <pre style="position: fixed; margin-top: 18vh; margin-left: 10.27vw; text-transform: uppercase;" class="mat-display-4 mc-1 overflow-hidden" innerHTML="{{'mc-1' | translate}}"></pre>
        <pre style="position: fixed; margin-top: 54.73vh; margin-left: 10.27vw;" class="mat-display-2 mc-1-1 overflow-hidden" innerHTML="{{'mc-1-1' | translate}}"></pre>
        <pre style="position: fixed; margin-top: 83.68vh; margin-left: 10.27vw; font-weight: bold; font-size: 3em;" class="mat-body-1 mc-1-2 overflow-hidden" innerHTML="{{'mc-1-2' | translate}}"></pre>
      </div>
    </div>
    <div class="section" id="courses-container">
      <img class="position-fixed" src="/images/courses_bg.svg" alt="coursesBg" style="height: inherit; width: 100%; object-fit: fill;" />
      <div class="mat-display-3 main-title">{{'Courses' | translate}}</div>
      <div class="slide">
        <div class="home-container container-padding">
          <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
            <div class="course-container">
              <img class="course-image" src="/images/foundation.svg" alt="foundationLogo" />
              <pre class="mat-display-3 overflow-hidden course-title">PRINCE2
FOUNDATION</pre>
              <pre class="mat-body-1 font-size-middle overflow-hidden course-description" innerHTML="{{'foundation' | translate}}"></pre>
              <a class="my-auto course-description" routerLink="/foundation-ru">{{'more_delails' | translate}}</a>
              <div class="course-button-container">
                <div *ngIf="currentCountry==='Россия'">
                  <button class="mat-body-1 b-secondary b-course-details mx-auto"
                          (click)="buyCourse('443BC788-7D51-EB11-80E1-00155D784603')"
                          mat-button color="accent">
                    {{'take_training' | translate}}
                  </button>
                </div>
                <div *ngIf="currentCountry==='Казахстан' || currentCountry==='Другая'">
                  <button class="mat-body-1 b-secondary b-course-details mx-auto"
                          (click)="buyCourse('F6FACACB-5FB7-ED11-88B6-A92673B5996B')"
                          mat-button color="accent">
                    {{'take_training' | translate}}
                  </button>
                </div>

              </div>
            </div>
            <!--div class="course-container">
                                    <img class="course-image" src="/images/practitioner.svg" />
                                    <pre class="mat-display-3 overflow-hidden course-title">PRINCE2
            PRACTITIONER</pre>
                                    <pre class="mat-body-1 font-size-middle overflow-hidden course-description">{{'pratiotioner' | translate}}</pre>
                                    <a class="my-auto course-description" routerLink="/practitioner-ru">{{'more_delails' | translate}}</a>
                                    <div class="course-button-container">
                                        <button class="mat-body-1 b-secondary b-course-details mx-auto"
                                                (click)="buyCourse('1AFE0911-7E51-EB11-80E1-00155D784603')"
                                                mat-button color="accent">{{'take_training' | translate}}</button>
                                    </div>
                                </div-->
          </div>
        </div>
      </div>
      <!--div class="slide">
                  <div class="home-container container-padding">
                      <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center">
                          <div class="course-container">
                              <img class="course-image" src="/images/foundation.svg" />
                              <pre class="mat-display-3 overflow-hidden course-title">PRINCE2
      FOUNDATION EN</pre>
                              <pre class="mat-body-1 font-size-middle overflow-hidden course-description" innerHTML="{{'foundation' | translate}}"></pre>
                              <a class="my-auto course-description" routerLink="/foundation-en">{{'more_delails' | translate}}</a>
                              <div class="course-button-container">
                                      <button class="mat-body-1 b-secondary b-course-details mx-auto"
                                              routerLink="/foundation-en"
                                              (click)="buyCourse('19D5FCF4-7D51-EB11-80E1-00155D784603')"
                                              mat-button color="accent">
                                          {{'take_training' | translate}}
                                      </button>
                              </div>
                          </div>
                          <div class="course-container">
                              <img class="course-image" src="/images/practitioner.svg" />
                              <pre class="mat-display-3 overflow-hidden course-title">PRINCE2
      PRACTITIONER EN</pre>
                              <pre class="mat-body-1 font-size-middle overflow-hidden course-description">{{'pratiotioner' | translate}}</pre>
                              <a class="my-auto course-description" routerLink="/practitioner-en">{{'more_delails' | translate}}</a>
                              <div class="course-button-container">
                                      <button class="mat-body-1 b-secondary b-course-details mx-auto"
                                              mat-button color="accent" (click)="buyCourse('A6064831-7E51-EB11-80E1-00155D784603')">
                                          {{'take_training' | translate}}
                                      </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div-->
    </div>
    <div class="section">
      <img class="position-fixed" src="/images/education_bg.svg" alt="educationBg" style="height: inherit; width: 100%; object-fit: fill;" />
      <div class="mat-display-3 main-title">{{'Education_process' | translate}}</div>
      <div class="slide">
        <div class="home-container" fxFlexFill fxLayout="column" fxLayoutAlign="space-evenly center">
          <pre class="ed-title mat-body overflow-hidden" innerHTML="{{'ed-1' | translate}}"></pre>
          <div *ngIf="!isLoading" class="ed-video-container">
            <vg-player>
              <vg-controls [vgAutohide]="true" [vgAutohideTime]="5">
                <vg-play-pause></vg-play-pause>
                <vg-playback-button></vg-playback-button>
                <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

                <vg-scrub-bar>
                  <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                  <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                </vg-scrub-bar>
                <vg-mute></vg-mute>
                <vg-volume></vg-volume>


                <vg-fullscreen></vg-fullscreen>
              </vg-controls>

              <video [vgMedia]="master" #master [vgMaster]="true" id="masterVideo" preload="metadata">
                <source src="/video/PRINCE2_FOUNDATION_RU_1.mp4" type="video/mp4">
              </video>
            </vg-player>
          </div>
          <div>
            <img src="/images/left.svg" onclick="" alt="left" />
            <img src="/images/right.svg" onclick="" alt="right" />
          </div>
        </div>
      </div>
      <div class="slide">
        <div class="home-container" fxFlexFill fxLayout="column" fxLayoutAlign="space-evenly center">
          <pre class="ed-title mat-body overflow-hidden" innerHTML="{{'ed-1' | translate}}"></pre>
          <div *ngIf="!isLoading" class="ed-video-container">
            <vg-player>
              <vg-controls>
                <vg-play-pause></vg-play-pause>

                <vg-scrub-bar>
                  <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                  <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                </vg-scrub-bar>

                <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>

                <vg-mute></vg-mute>

                <vg-fullscreen></vg-fullscreen>
              </vg-controls>

              <video [vgMedia]="master1" #master1 id="masterVideo1" preload="metadata">
                <source src="/video/PRINCE2_FOUNDATION_RU_11.mp4" type="video/mp4">
              </video>
            </vg-player>
          </div>
          <div>
            <img src="/images/left.svg" onclick="" alt="left" />
            <img src="/images/right.svg" onclick="" alt="right" />
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <img class="position-fixed " src="/images/comments_bg.svg" alt="commentsBg" style="height: inherit; width: 100%; object-fit: fill;" />
      <div class="home-container" fxFlexFill>
        <div class="mat-display-3 main-title">{{'Comments' | translate}}</div>
        <div class="comments-button-bar">
          <img src="/images/left.svg" alt="left" onclick="document.getElementsByClassName('fa-angle-left')[0].click();" />
          <img src="/images/right.svg" alt="right" onclick="document.getElementsByClassName('fa-angle-right')[0].click();" />
        </div>
        <div fxLayout="row"
             fxFlexFill
             fxLayoutAlign="stretch center">
          <div fxFlex class="comments-container">
            <owl-carousel-o *ngIf="!isResized" [options]="customOptions" (translated)="getPassedData($event)">
              <ng-container *ngFor="let feedback of comments">
                <ng-template class="slide" carouselSlide [id]="feedback.id">
                  <mat-card class="example-header-image comments mat-elevation-z8 m-3">
                    <mat-card-header class="align-items-center">
                      <div mat-card-avatar class="user-image"></div>
                      <mat-card-title class="user-name">{{feedback?.contact?.firstName}} {{feedback?.contact?.lastName}}</mat-card-title>
                      <mat-card-subtitle class="sub-text mat-body-2">{{feedback?.feedBackDate | localizedDate:'longDate' }}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                      <div class="row">
                        <div class="col-auto mat-body-2 sub-text">{{'Teaching' | translate}}<ngb-rating class="col-2" [max]="5" [starTemplate]="t" [(rate)]="feedback.teachingQualityRate" [readonly]="true"></ngb-rating></div>
                        <div class="col-auto mx-auto mat-body-2 sub-text">{{'Educational_materials' | translate}}<ngb-rating class="col-2" [max]="5" [starTemplate]="t" [(rate)]="feedback.materialsQualityRate" [readonly]="true"></ngb-rating></div>
                        <div class="col-auto mat-body-2 sub-text">{{ 'Learning_portal' | translate}}<ngb-rating class="col-2" [max]="5" [starTemplate]="t" [(rate)]="feedback.portalUsabilityRate" [readonly]="true"></ngb-rating></div>
                      </div>
                      <p class="mat-body comment-content">{{feedback.text}}</p>
                    </mat-card-content>
                  </mat-card>
                  <!--img class="comments" [src]="slide.url" [alt]="slide.id" [title]="slide.id"-->
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <img class="position-absolute " src="/images/about_bg.svg" alt="aboutbg" style="height: inherit; width: 100%; object-fit: fill;" />
      <div class="home-container" fxLayout="column" fxFlexFill>
        <div class="mat-display-3 main-title ab-title" color="accent">{{'About_us' | translate}}</div>
        <div class="ab-container" fxFlex="66.45" fxLayout="row" fxLayoutAlign="space-between space-around">
          <div fxFlex="68" fxLayout="column" style="z-index: 50 !important;" fxLayoutAlign="space-between stretch">
            <div *ngIf="currentCountry ==='Россия'">
              <pre class="ab-1 mat-body">{{'aboutru' | translate}}</pre>
            </div>
            <div *ngIf="currentCountry === 'Казахстан' || currentCountry === 'Другая'">
              <pre class="ab-1 mat-body">{{'aboutkz' | translate}}</pre>
            </div>

            <div class="mat-display-1 ab-2 ab-title mt-auto">{{'about-2' | translate}}</div>

            <a class="my-auto" routerLink="/additional-services">{{'Additional_services' | translate}}</a>
            <p class="ab-1 mat-body">{{'Mop_text' | translate}}</p>
          </div>
          <div fxFlex="15" class="mrg-right" style="z-index: 50 !important;">
            <img class="cert-img mx-auto" style="margin-top: 0;" src="/images/Group_56.svg" alt="group" />
            <img class="cert-img mx-auto" src="/images/Group_57.svg" alt="group" />
          </div>
        </div>

        <div class="ab-footer" fxLayout="row" fxFlex="25.55">
          <div fxFlex="25">
            <div fxLayout="column" fxFlexFill>
              <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 12em;" />
              <img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />
              <div fxFlex fxLayoutAlign="start center">
                <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
  <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
                <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
                <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />
                 <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard" />
                 <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa" />
                
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex="20">
            <div fxLayout="column" fxFlexFill>

              <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" data-menuanchor="courses" href="/#courses">{{'Courses' | translate}}</a>
              <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" data-menuanchor="education" href="/#education">{{'Education_process' | translate}}</a>
              <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" data-menuanchor="comments" href="/#comments">{{'Comments' | translate}}</a>
              <a fxFlex="30" class="mat-title footer-text" data-menuanchor="about" href="/#about">{{'About_us' | translate}}</a>

            </div>



          </div>
          <div fxLayout="column" fxFlex="30">
            <div fxLayout="column" fxFlexFill>
              <a fxFlex="20" class="mat-title footer-text" routerLink="/additional-services" style="margin-bottom: 0.55vh;">{{'Additional_services' | translate}}</a>
              <a *ngIf="currentCountry==='Россия'" fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="margin-bottom: 1.20vh;">{{'support' | translate}}</a>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/payment-rules">{{'Pravila_Payment' | translate}}</a>
              </div>
              <div *ngIf="currentCountry==='Россия'">
                <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/oferta">{{'public_offer_1' | translate}}</a>
              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh; color: white !important;" routerLink="/ofertakz">{{'public_offer_1' | translate}}</a>
              </div>
              <div *ngIf="currentCountry==='Россия'">
                <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacy">{{'Personal_data_processing_policy' | translate}}</a>
              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacykz">{{'Personal_data_processing_policy' | translate}}</a>
              </div>

            </div>
          </div>

          <div fxLayout="column" fxFlex="30">
            <div fxLayout="column" fxFlexFill>
              <div *ngIf="currentCountry==='Россия'">
                <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addressru' | translate}}</p>
              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addresskz' | translate}}</p>
              </div>
              <div *ngIf="currentCountry==='Россия'">
                <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numru' | translate}}</p>
              </div>
              <!--<div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numkz' | translate}}</p>
              </div>-->
              <a fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>
              
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


</div>


 
 
  

  <ng-template #t let-fill="fill" let-index="index">
    <span class="star" [class.full]="fill === 100">
      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
    </span>
  </ng-template>

  <mat-menu #langMenu="matMenu" class="language-mobile language-table">
    <button mat-menu-item *ngFor="let language of translate.getLangs()"
            (click)="switchLang(language)">
      <span class="language-mobile">{{language | translate}}</span>
    </button>
  </mat-menu>

  <mat-menu #menu="matMenu">
    <button (click)="onClick(option)" *ngFor="let option of options" mat-menu-item>

      <span class="language-mobile">{{option}}</span>
    </button>
    <!--<button mat-menu-item value="1"><span class="language-mobile">Россия</span></button>
  <button mat-menu-item value="2"><span class="language-mobile">Казахстан</span></button>-->
  </mat-menu>

  <mat-menu #contactMenu="matMenu">
    <button mat-menu-item
            (click)="cabinet()">
      <span>{{'cabinet' | translate}}</span>
    </button>
    <button mat-menu-item
            (click)="logout()">
      <span>{{'logout' | translate}}</span>
    </button>
  </mat-menu>
