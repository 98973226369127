
<div class="d-sm-block d-md-block d-lg-none">
  <div class="ab-footer-mobile ab-footer-table" fxLayout="column" fxFlex="25.55">
    <div fxFlex="21" class="" style="">
      <div fxLayout="column" fxFlexFill style="">
        <img src="/images/ComProductsLogo.svg" class="" alt="comproductsLogo" style="width: 30em;" />
        <!--<img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />-->
        <!--<div fxFlex fxLayoutAlign="start" style="padding-top: 1.85vh;">-->
        <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
        <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
        <!--<img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
        <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />-->
      <!--</div>-->
      </div>
    </div>
    <div fxLayout="column" class="" fxFlex="22" style="margin-left: 3.73vw;">
      <div fxLayout="column" class="pt-2" fxFlexFill>

        <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile"  (click)="goDown1()" style="">{{'Courses' | translate}}</a>
        <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile"  (click)="goDown2()" style="">{{'Education_process' | translate}}</a>
        <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile"  (click)="goDown3()" style="">{{'Comments' | translate}}</a>
        <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile"  (click)="goDown4()" style="">{{'About_us' | translate}}</a>

      </div>



    </div>
    <mat-divider class="margin-mobile"></mat-divider>
    <div fxLayout="column" fxFlex="30" class="py-2" style="margin-left: 3.73vw;">
      <div fxLayout="column" fxFlexFill>
        <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" routerLink="/additional-services" style="">{{'Additional_services' | translate}}</a>
        <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="">{{'support' | translate}}</a>
        <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table ofertaCss" style="" innerHTML="{{'public_offer_1' | translate}}"></a>
        <a fxFlex="30" class="mat-title footer-text-2-mobile footer-text-2-table mb-0 ofertaCss" style="color: white !important;" innerHTML="{{'Personal_data_processing_policy' | translate}}"></a>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div fxLayout="column" class="py-2" fxFlex="30" style="margin-left: 3.73vw;">
      <div fxLayout="column" fxFlexFill>
        <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'address' | translate}}</p>
        <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_num' | translate}}</p>
        <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" style="" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>
      </div>
    </div>

    <div fxLayout="column" fxFlex="22" style="margin-left: 3.73vw;">
      <div fxLayout="row" fxFlexFill>

        <img src="/images/axelos.svg" class="axelos-img-mobile axelos-img-table mb-2" alt="axelosLogo" style="" />
        <div fxFlex fxLayoutAlign="start" style="align-items:center;margin-left: 20px;">
          <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img-mobile footer-contact-img-table " />
          <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img-mobile footer-contact-img-table" />-->
          <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img-mobile footer-contact-img-table" />
          <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img-mobile footer-contact-img-table" />
        </div>

      </div>



    </div>

  </div>
</div>



<!--<div class="d-lg-none d-none d-sm-block">
  <div class="ab-footer" fxLayout="row" fxFlex="25.55">
    <div fxFlex="21" style="">
      <div fxLayout="column" fxFlexFill>
        <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 12em;" />
        <img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />
        <div fxFlex fxLayoutAlign="start" style="padding-top: 1.85vh;">
          <img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
          <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />
          <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
          <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="22" style="">
      <div fxLayout="column" fxFlexFill>

        <a fxFlex="10" class="mat-title footer-text" routerLink="/" fragment="courses" style="font-size: 1.6em;">{{'Courses' | translate}}</a>
        <a fxFlex="20" class="mat-title footer-text" routerLink="/" fragment="education" style="margin-bottom: 1.20vh;font-size:1.6em;">{{'Education_process' | translate}}</a>
        <a fxFlex="10" class="mat-title footer-text" routerLink="/" fragment="comments" style="font-size: 1.6em;">{{'Comments' | translate}}</a>
        <a fxFlex="10" class="mat-title footer-text" routerLink="/" fragment="about" style="font-size: 1.6em;">{{'About_us' | translate}}</a>

      </div>



    </div>
    <div fxLayout="column" fxFlex="30" style="">
      <div fxLayout="column" fxFlexFill>
        <a fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="margin-bottom: 0.46vh; font-size:1.6em;">{{'support' | translate}}</a>
        <a fxFlex="20" class="mat-title footer-text" routerLink="/oferta" href="" target="_blank" style="margin-bottom: 1.20vh;font-size: 1.6em;">{{'public_offer_1' | translate}}</a>
        <a fxFlex="30" class="mat-title footer-text" routerLink="/privacy" href="" target="_blank" style="font-size: 1.6em;">{{'Personal_data_processing_policy' | translate}}</a>
      </div>
    </div>

    <div fxLayout="column" fxFlex="30" style="">
      <div fxLayout="column" fxFlexFill>
        <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;font-size:1.6em;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'address' | translate}}</p>
        <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;font-size:1.6em;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />+7 925 739 79 45</p>
        <a fxFlex="20" class="mat-title footer-text" style="font-size: 1.6em;" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>
      </div>
    </div>

  </div>
  </div>-->

  <div class="d-none d-lg-inline">
    <div class="ab-footer" fxLayout="row" fxFlex="25.55">
      <div fxFlex="25">
        <div fxLayout="column" fxFlexFill>
          <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 12em;" />
          <img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />
          <div fxFlex fxLayoutAlign="start end">
            <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
        <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
            <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
            <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="20">
        <div fxLayout="column" fxFlexFill>

          <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" data-menuanchor="courses" href="/#courses">{{'Courses' | translate}}</a>
          <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" data-menuanchor="education" href="/#education">{{'Education_process' | translate}}</a>
          <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" data-menuanchor="comments" href="/#comments">{{'Comments' | translate}}</a>
          <a fxFlex="30" class="mat-title footer-text" data-menuanchor="about" href="/#about">{{'About_us' | translate}}</a>

        </div>



      </div>
      <div fxLayout="column" fxFlex="30">
        <div fxLayout="column" fxFlexFill>
          <a fxFlex="20" class="mat-title footer-text" routerLink="/additional-services" style="margin-bottom: 0.55vh;">{{'Additional_services' | translate}}</a>
          <a *ngIf="currentCountry==='Россия'" fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="margin-bottom: 1.20vh;">{{'support' | translate}}</a>
          <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
            <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/payment-rules">{{'Pravila_Payment' | translate}}</a>
          </div>
          <div *ngIf="currentCountry==='Россия'">
            <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/oferta">{{'public_offer_1' | translate}}</a>
          </div>
          <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
            <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh; color: white !important;" routerLink="/ofertakz">{{'public_offer_1' | translate}}</a>
          </div>
          <div *ngIf="currentCountry==='Россия'">
            <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacy">{{'Personal_data_processing_policy' | translate}}</a>
          </div>
          <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
            <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacykz">{{'Personal_data_processing_policy' | translate}}</a>
          </div>

        </div>
      </div>

      <div fxLayout="column" fxFlex="30">
        <div fxLayout="column" fxFlexFill>
          <div *ngIf="countrySwap==='RU'">
            <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addressru' | translate}}</p>
          </div>
          <div *ngIf="countrySwap==='KZ'">
            <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addresskz' | translate}}</p>
          </div>
          <div *ngIf="countrySwap==='RU'">
            <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numru' | translate}}</p>
          </div>
          <!--<div *ngIf="countrySwap==='KZ'">
            <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numkz' | translate}}</p>
          </div>-->
          <a fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>
        </div>
      </div>

    </div>
  </div>
