<div class="sidenav_container sidenav_container-mobile sidenav_container-table">
  <div fxLayout="column" style="height: 100vh; overflow-x:hidden;">
    <div style="margin-top: 35px;">
      <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;margin-left:18px; text-align:center; padding-left:8px;">
        <div fxLayout="column" fxLayoutAlign="start start" style="" class="">
          <a (click)="slideTo('courses')" style="" class="phone-text">{{ 'Courses' | translate }}</a>
          <a (click)="slideTo('education')" style=" margin-top: 14px;" class="phone-text">{{ 'Education_process' | translate }}</a>
          <a (click)="slideTo('feedback')" style=" margin-top: 14px;" class="phone-text">{{ 'Comments' | translate }}</a>
          <a (click)="slideTo('aboutUs')" style=" margin-top: 14px;" class="phone-text">{{ 'About_us' | translate }}</a>
        </div>
        <button mat-icon-button (click)="onSidenavClose()" style="margin-right: 6px; top: 0; right: 0; position: absolute; margin-top: 21px;">
          <img style="width:20px;margin-left:10px;margin-right:17px;" src="/images/menu_close_light.svg" alt="menuClose" />
        </button>
      </div>
    </div>

    <div></div>
  
    <div class=" mt-auto justify-content-around" style="padding-bottom:26px;margin-left:18px;">

      <div *ngIf="currentCountry==='Россия'">
        <p class="phone-mobile phone-table">{{'phone_numru' | translate}}</p>
      </div>
      <!--<div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
        <p class="phone-mobile phone-table">{{'phone_numkz' | translate}}</p>
      </div>-->

      <!--<img class="img-logo" src="/images/facebook.svg" alt="Facebook" />
  <img class="img-logo" src="/images/instagram.svg" alt="Instagram" />-->
      <img class="img-logo" src="/images/vk.svg" alt="VK" />
      <img class="img-logo" src="/images/telegram.svg" alt="Telegram" />
    </div>
  </div>
</div>
